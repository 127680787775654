<template>
	<div class="main main-privacy">
		<div class="container-fluid">
			<div class="row page-title">
				<div class="col-4 d-flex align-items-center justify-content-start">
					<img src="../assets/images/safeway.png" alt="Safeway / Albertsons are the exclusive grocery partner of the Portland Timbers " class="img-fluid">
				</div>
				<div class="col-4  d-flex align-items-center justify-content-center">
					<h1 class="text-center py-5 m-0">Privacy Policy</h1>
				</div>
				<div class="col-4 d-flex align-items-center justify-content-end">
					<img src="../assets/images/logo-at-the-top.png" class="img-fluid logo-score-save">
				</div>
			</div>
			<div class="box bg-green p-4 p-lg-5 mt-5 border-2">
				<div class="container">
					<p>Coming Soon</p>
				</div>
            </div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "privacy",
		data() {
			return {};
		},
		mounted() {
			$(".main-privacy a").attr({ target: "_blank", rel: "noopener" });
		}
	};
</script>

<style lang="scss" scoped>
h2 {
    @include h4;
}
</style>
