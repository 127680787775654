/*!
	Hash Smooth Scrolling by Daniel Flynn & Bryan Oakley

	Usage:
		Add data-anchor attributes matching a link's hash to elements on the page where you would normally put an id
		Optional:
			data-anchor-min-duration	=> Controls the minimum time to scroll (default: 250ms)
			data-anchor-max-duration	=> Controls the maximum time to scroll (default: 1000ms)
			data-anchor-speed			=> Controls the speed of the scroll (default: 1 movement of the window height in px per 250ms)
			data-anchor-easing			=> Controls the easing used for the animation (default: swing)

			These can be placed on either the link, the element, or the body with the data-anchor tag. Priority is as follows: Link, Element, Body
*/

(function($) {
	var e = window, a = 'inner';
	if (!('innerWidth' in window )) {
        a = 'client';
        e = document.documentElement || document.body;
    }

	var cancelScroll = false,
		$doc = $(document);

	var action = function(target, opt, targetContainer) {
		cancelScroll = false;

		var animTarget,
				targetOffset;

		if (targetContainer)
		{
			animTarget = targetContainer;
			targetOffset = Math.min(targetContainer.prop('scrollHeight') - targetContainer.height(), (target.offset().top + targetContainer.scrollTop()) - targetContainer.offset().top + opt.offset);
		}
		else
		{
			animTarget = $doc;
			targetOffset = Math.min($doc.height() - $(window).height(), target.offset().top + opt.offset);
		}

		var speed = 0,
			obj = {x: animTarget.scrollTop()};
			
		if (opt.speed)
		{
			speed = Math.abs(obj.x - targetOffset) / opt.speed * 1000;
			if (speed > opt.maxDuration)
				speed = opt.maxDuration;
			else if (speed < opt.minDuration)
				speed = opt.minDuration;
		}

		var resolveFn;
		var promise = new Promise((resolve) => {
			resolveFn = resolve;
		});

		$(obj).stop().animate({
			x: targetOffset
		}, {
			duration: speed,
			easing: opt.easing,
			step: function(now, tween) {
				animTarget.scrollTop(now);
			},
			complete: function() {
				resolveFn();
			}
		});

		return promise;
	};

	var helper = function(opt) {
		var target = $('[data-anchor="'+opt.hash+'"]');
		if (!target.length)
			target = $('[data-anchor="'+opt.hash.substr(1)+'"]');
		if (!target.length)
			return;

		var targetContainer = $(target.data('anchor-parent'));
		if (!targetContainer.length)
			targetContainer = target.parents('.anchor-container');

		opt.speed = opt.speed || parseInt(target.data('anchor-speed')) || parseInt(targetContainer.data('anchor-speed')) || parseInt($('body').data('anchor-speed')) || (e[a + 'Height'] * 4);
		opt.minDuration = opt.maxDuration || parseInt(target.data('anchor-min-duration')) || parseInt(targetContainer.data('anchor-min-duration')) || parseInt($('body').data('anchor-min-duration')) || 250;
		opt.maxDuration = opt.maxDuration || parseInt(target.data('anchor-max-duration')) || parseInt(targetContainer.data('anchor-max-duration')) || parseInt($('body').data('anchor-max-duration')) || 1000;
		opt.easing = opt.easing || target.data('anchor-easing') || targetContainer.data('anchor-easing') || $('body').data('anchor-easing') || 'swing';
		opt.offset = opt.offset || target.data('anchor-offset') || targetContainer.data('anchor-offset') || $('body').data('anchor-offset') || 0;

		if (opt.delay)
		{
			return new Promise((resolve) => {
				setTimeout(function() {
					action(target, opt, targetContainer.length ? targetContainer : undefined).then(resolve);
				}, opt.delay);
			});
		}
		else {
			return action(target, opt, targetContainer.length ? targetContainer : undefined);
		}
	};

	$.smoothAnchor = function(hash, opts) {
		return helper(Object.assign({hash: hash}, opts || {}));
	};
})(jQuery);