<template>
	<modal classes="modal-red">
		<h5 slot="header" slot-scope="{id}" :id="id">Please Wait...</h5>
		<div slot="body" class="text-center">
			<fa-icon icon="faCog" spin size="lg"></fa-icon>
		</div>
	</modal>
</template>

<script>
	export default {
		name: 'pleaseWait',

		props: {
			modal: {
				type: Object,
				required: true
			}
		}
	}
</script>