<template>
	<modal classes="modal-stars" size="lg">
		<h5 class="modal-title text-center" slot="header" v-html="title"></h5>
		<div slot="body">
			<lang id="browser-settings-content">
				<ul>
					<li>Your browser appears to have cookies disabled.</li>
					<li>For Safari Users (iOS/macOS) you may need to Allow Cross Site Tracking
						<ul>
							<li> iOS - Settings &rarr; Safari &rarr; Prevent Cross-Site Tracking (turn off)</li>
							<li> macOS - Safari &rarr; Preferences &rarr; Privacy &rarr; Prevent Cross-site tracking (uncheck)</li>
							<li> You can reset your privacy settings after you register or sign in.</li>
						</ul>
					</li>
					<li>For Mac only: Try using another browser such as Chrome or Firefox.</li>
				</ul>
			</lang>
		</div>
		<button slot="footer" type="button" class="btn btn-primary btn-block" @click="ok" v-ada>Okay, Got it!</button>
	</modal>
</template>

<style>

</style>
<script>
	export default {
		name:    'browserSettingsWarning',
		props:   {
			modal: {
				type: Object,
				required: true
			},
			title: {
				type:    String,
				default: 'Notice'
			}
		},
		methods: {
			ok() {
				this.modal.trigger('close');
                // force reload guarantees the captcha JS file will be reloaded from server
				// top.location.href = '/';
				top.location.reload(true);
                // this.$router.replace({name:'home'});
            }
        }
    }
</script>
