import {make} from 'vuex-pathify';

export default {
	namespaced: true,
	state: {
		alert: ''
	},
	mutations: {
		...make.mutations(['alert'])
	},
	getters: {
		...make.getters(['alert'])
	}
}