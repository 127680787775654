/**
 * These are static, environment-specific values.
 * For user-specific values refer to the global session store.
 *   for instance: $store.get('auth/sessionData')
 *
 * If `npm run build adaptive` was used then the runtime configuration will be changed
 * for QA or localdev, using the location.hostname. default configuration is production.
 * serverUrl - API URL
 *
 */
function configValues() {

	let config = {
		NODE_ENV:            process.env.NODE_ENV,
		serverUrl:           process.env.VUE_APP_BASE_URL,
		hostRegistrationUrl: '',
		useCaptcha:          false,
		useTrackers:         !/localhost|\.local$/.test(location.hostname)
	}

	if (/^www\.timbersshopandscore(codes)?\.com$/.test(location.hostname)) {
		config = {
			NODE_ENV:       'production',
			serverUrl:      'https://www.timbersshopandscore.com',
			loggedInDomain: 'www.timbersshopandscorecodes.com',
			guestDomain:    'www.timbersshopandscore.com',
			useCaptcha:     true,
			useTrackers:    true
		}
	} else if (/^qa\./.test(location.hostname)) {
		config = {
			NODE_ENV:       'staging',
			serverUrl:      'https://qa.timbersshopandscore.com',
			loggedInDomain: 'qa.timbersshopandscorecodes.com',
			guestDomain:    'qa.timbersshopandscore.com',
			useCaptcha:     true,
			useTrackers:    true
		}
	} else if (/localhost|\.local$/.test(location.hostname)) {
		let host = location.hostname.replace('codes', '');
		config = {
			NODE_ENV:       'local',
			serverUrl:      'http://www.timbersshopandscore.local',
			loggedInDomain: 'localhost',
			guestDomain:    'localhost',
			useCaptcha:     false,
			useTrackers:    false
		}
	}
	config.secureServerUrl = config.serverUrl.replace('timbersshopandscore.com', 'timbersshopandscorecodes.com');
	return config;
}

let config = {
	env:             configValues().NODE_ENV,
	serverUrl:       configValues().serverUrl,
	secureServerUrl: configValues().secureServerUrl,
	appVersion:      process.env.VUE_APP_APP_VERSION,
	useTrackers:     configValues().useTrackers,
	useCaptcha:      configValues().useCaptcha,
	loggedInDomain:  configValues().loggedInDomain,
	guestDomain:     configValues().guestDomain,
	// the key used on the Choose Store page matters, since the server is expecting specific values
	banners: {},

	recaptcha: {
		siteKey: '6Ld2mvMaAAAAAKw9WKd45yVTyMFOUOVTuxGNK2PO'
	}
}
if (process.env.useHostBasedConfig) {
	config = process.env.config.production;
	if (/^qa\./.test(location.hostname)) {
		config = process.env.config.qa;
	}

	if (/localhost|\.local$/.test(location.hostname)) {
		config = process.env.config.localdev
	}
}

config.storeListUrl = config.serverUrl + '/app/stores';

// localUrl used to retrieve assets that could potentially be available locally if the app is running
config.localUrl = config.serverUrl;

config.usingLoggedInDomain = location.hostname === config.loggedInDomain;
config.usingGuestDomain = location.hostname === config.guestDomain;

// don't check and possibly redirect if the domain name is irrelevant (using app or localhost)
config.domainCheckRequired = !process.appVersion && (config.usingGuestDomain || config.usingLoggedInDomain) && config.loggedInDomain !== config.guestDomain;
export default config;
