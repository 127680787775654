<template>
	<modal classes="modal-stars leaving-modal" size="lg">
		<h5 class="modal-title text-center" slot="header">
			<lang id="leaving-app-title">
				Continue to website
			</lang>
		</h5>
		<div slot="body" class="description">
			<lang id="leaving-app-content">
				Warning: You are leaving the app. Click continue if you wish to login to the game on the website to view and print prize forms.
			</lang>
		</div>
		<div slot="footer">
			<button type="button" class="btn btn-primary btn-sm" @click="closeModal" v-ada>Close</button>
			<a class="btn btn-blue btn-sm" href="http://www..com">Continue</a>
		</div>
	</modal>
</template>
<style>

</style>
<script>
	export default {
		name: 'LeavingApp',

		props: {
			modal: {
				type: Object,
				required: true
			}
		},

		methods: {
			closeModal() {
				this.modal.trigger('close');
			}
		}
	}
</script>
