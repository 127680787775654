<template>
	<modal classes="modal-red">
		<h5 slot="header">
			<lang id="no-longer-authenticated-title">
				Session expired
			</lang>
		</h5>
		<div slot="body" class="text-center">
			<lang id="no-longer-authenticated-content">
				Your session has timed out.
				<br>Please log in to continue.
			</lang>
		</div>

	</modal>
</template>

<script>
	export default {
		name: 'noLongerAuthenticated',

		props: {
			modal: {
				type: Object,
				required: true
			}
		}
	}
</script>