<template>
	<div class="row align-items-stretch flex-column flex-lg-row">
		<div v-for="col in cols" :key="col" class="col my-3 mx-3" v-if="showColumn(col)">
            <!-- History -->
            <div v-if="col === 'history-left'" class="green-orange-gradient-vertical text-light rounded p-4 py-lg-5 px-lg-1 text-center mb-5 h-75">
                <!-- <p class="h3 font-weight-normal color-yellow">Code History</p>
                <p class="font-weight-normal text-center">Check your Code History to see what you've entered so far.</p> -->
                <router-link :to="{ name: 'code-history' }" class="text-decoration-none d-block color-yellow">
					<img src="../assets/images/code-history.png" alt="" role="presentation" class="img-fluid mb-4 blurb-img position-relative">
                    <p class="h3 font-weight-normal text-light position-relative">Code History</p>
                </router-link>
            </div>

            <!-- Winners -->
			<div v-if="col === 'winners' && hasWinners" class="green-orange-gradient-vertical text-light rounded p-4 py-lg-5 px-lg-1 text-center mb-5 h-75">
				<router-link :to="{ path: '/winners'}" class="text-decoration-none d-block color-yellow">
					<img src="../assets/images/money-2.png" alt="" role="presentation" class="img-fluid mb-4 blurb-img position-relative">
                    <p class="h3 font-weight-normal text-light position-relative">The Winners</p>
				</router-link>
				<!-- Take a look at the current winners! -->
			</div>

			<!-- FAQ -->
			<div v-if="col === 'faq'" class="green-orange-gradient-vertical text-light rounded p-4 py-lg-5 px-lg-1 text-center mb-5 h-75">
				<router-link :to="{ name: 'faq' }" class="text-decoration-none d-block color-yellow">
					<img v-if="associate" src="../assets/images/faq.png" alt="" role="presentation" class="img-fluid mb-4 blurb-img position-relative">
					<img v-else src="../assets/images/faq.png" alt="" role="presentation" class="img-fluid mb-4 blurb-img position-relative">
                    <p class="h3 font-weight-normal text-light position-relative">Have Questions?</p>
				</router-link>
				<!-- Check our FAQ page for answers. -->
			</div>

            <!-- RULES -->
            <div v-if="col === 'rules'" class="green-orange-gradient-vertical text-light rounded p-4 py-lg-5 px-lg-1 text-center mb-5 h-75">
                <router-link :to="{ name: 'rules' }" class="text-decoration-none d-block color-yellow">
                    <img v-if="isEnterCode()" src="../assets/images/official-rules.png" alt="" role="presentation" class="img-fluid mb-5 blurb-img position-relative">
                    <img v-else src="../assets/images/rules.png" alt="" role="presentation" class="img-fluid mb-5 blurb-img position-relative">
                    <p class="h3 font-weight-normal text-light position-relative">Official Rules</p>
                </router-link>
                <!-- View official rules on the Rules page. -->
            </div>
            
            <!-- Play Game -->
            <div v-if="col === 'play-game'" class="green-orange-gradient-vertical text-light rounded p-4 py-lg-5 px-lg-1 text-center mb-5 h-75">
                <router-link :to="{ name: 'enter-code' }" class="text-decoration-none d-block color-yellow">
                    <img src="../assets/images/play-game.png" alt="" role="presentation" class="img-fluid mb-5 blurb-img position-relative">
                    <p class="h3 font-weight-normal text-light position-relative">Play the game</p>
                </router-link>
                <!-- Log in and submit your codes. -->
            </div>
            
		</div>
	</div>
</template>

<script>
	export default {
		props: {
			cols: {
				type: Array,
				validation(value) {
					return value.length == 2;
				},
			},
		},
        computed: {
		    hasWinners(){
                let pages = this.$store.get('auth/sessionData@pages');
                if (!pages)
                    return false;
                let found = false;
                pages.forEach(value=>{
                    if (value.name === 'winners'){
                        found=true;
                    }
                })
                return found;
            },
            associate() {
                return /employee|associate/i.test(this.$store.get('auth/currentUserType'));
            }
        },
        methods:  {
            showColumn(col) {
                if (col==='winners' && !this.hasWinners){
                    return false;
                }
                return true;
            },
            isEnterCode() {
                return this.$route.matched.some(({ name }) => name === 'enter-code');
            }
        }
    };
</script>

<style lang="scss" scoped>
.bg-balls {
    background-image: url(../assets/images/balls.png) ;
    background-repeat:no-repeat;
    background-size: auto;
    background-position: 90% 50%;
}
.position-relative {
    position: relative;
    top: -70px;
    @media(min-width: $lg) {
        top: -100px;
    }
    .enter-code-route & {
        top: -70px;
    }
}
p {
    &.position-relative {
        .enter-code-route & {
            top: -40px;
        }
    }
}
.green-orange-gradient-vertical {
    .enter-code-route & {
        background: #0d280b url(../assets/images/bg-home2.jpg) 10% 100% no-repeat;
    }
}
</style>
