<template>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-sm-8">
                <div class="bg-gray text-dark d-flex flex-column mx-auto mb-100 mt-4 mb-5 rounded p-4 p-xl-5">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="w-100">
                                <div class="font-weight-bold pb-3 font-size-large">Register</div>
                                <p class="small text-dark font-italic mb-3">All fields marked with * are required</p>
                                <!-- <FormulateForm
                                    name="registrationForm"
                                    @submit="submit"
                                    :schema="registrationForm"
                                    v-model="formData"

                                    :form-errors="formErrors"
                                >
                                    <recaptcha3 :siteKey="$config.useCaptcha && $config.recaptcha.siteKey"></recaptcha3>

                                    <submit-button class="btn btn-primary" ref="btnSubmit" :loading="busy">Submit</submit-button>
                                </FormulateForm> -->
                                <FormulateForm name="registrationForm" @submit="submit" v-model="formData" class="form reg-form" :form-errors="formErrors" :errors="formFieldErrors">
                                    <FormulateInput name='first_name'
                                                    label='First Name*'
                                                    type='text'
                                                    validation='required'
                                                    validationName='First name'
                                                    class='col-12 col-md-6 d-inline-block px-0 pr-md-3 align-top'
                                                    value=''
                                                    label-position='before'/>

                                    <FormulateInput name='last_name'
                                                    label='Last Name*'
                                                    type='text'
                                                    validation='required'
                                                    validationName='Last name'
                                                    class='col-12 col-md-6 d-inline-block px-0 pl-md-3 align-top'
                                                    value=''/>

                                    <FormulateInput name='email'
                                                    label='Email*'
                                                    type='text'
                                                    validation='email'
                                                    class='col-12 col-md-6 d-inline-block px-0 pr-md-3 align-top'
                                                    value=''/>

                                    <FormulateInput name='phone'
                                                    label='Phone*'
                                                    type='text'
                                                    validation='min:10,length'
                                                    validationName='Contact phone number with area code'
                                                    class='col-12 col-md-6 d-inline-block px-0 pl-md-3 align-top'
                                                    value=''/>

                                    <FormulateInput name='address'
                                                    label='Address*'
                                                    type='text'
                                                    validation='required'
                                                    validationName='Address'
                                                    class='col-12 col-md-6 d-inline-block px-0 pr-md-3 align-top'
                                                    value=''/>

                                    <FormulateInput name='city'
                                                    label='City*'
                                                    type='text'
                                                    validation='required'
                                                    validationName='City'
                                                    class='col-12 col-md-6 d-inline-block px-0 pl-md-3 align-top'
                                                    value=''/>

                                    <FormulateInput name='state'
                                                    label='State*'
                                                    type='select'
                                                    :options='states'
                                                    validation='required'
                                                    validationName='State'
                                                    class='col-12 col-md-6 d-inline-block px-0 pr-md-3 align-top'
                                                    value=''/>

                                    <FormulateInput name='zip'
                                                    label='ZIP*'
                                                    type='text'
                                                    validation='required'
                                                    validationName='ZIP Code'
                                                    class='col-12 col-md-6 d-inline-block px-0 pl-md-3 align-top'
                                                    value=''/>

                                    <FormulateInput name='password'
                                                    label='Password*'
                                                    type='password'
                                                    validation='required|min:6,length'
                                                    class='col-12 col-md-6 d-inline-block px-0 pr-md-3 align-top'
                                                    value=''/>

                                    <FormulateInput name='password_confirm'
                                                    label='Verify Password*'
                                                    type='password'
                                                    validation='confirm'
                                                    validationName='The password you entered'
                                                    class='col-12 col-md-6 d-inline-block px-0 pl-md-3 align-top'
                                                    value=''/>

                                    <FormulateInput name='store_id'
                                                    label='Where did you get your game ticket?*'
                                                    type='storeChooser'
                                                    validation='required'
                                                    validationName="Store"
                                                    class='col-12 col-md-6 d-inline-block px-0 pr-md-3 align-top'
                                                    value=''/>

                                    <FormulateInput name='old_enough'
                                                    label='I am at least eighteen (18) years of age or older*'
                                                    validationName='age requirement'
                                                    type='checkbox'
                                                    validation='accepted'
                                                    value=''/>

                                    <FormulateInput name='newsletter_albertsons' label='I wish to receive further communication from Safeway/Albertsons.' type='checkbox' value=''/>

                                    <FormulateInput name='agree_to_terms'
                                                    validationName='rules and regulations'
                                                    type='checkbox'
                                                    validation='accepted'
                                                    value=''>
                                                    <template #label="{ id }">
                                                        <label :for="id" class="formulate-input-label formulate-input-label--after">
                                                            I have read and agree to the <router-link to="/rules" v-on:click.native="toggleMenu(false)">Official Rules</router-link>.*
                                                        </label>
                                                    </template>
                                    </FormulateInput>

                                    <recaptcha3 v-if="$config.useCaptcha" :siteKey="$config.recaptcha.siteKey" ref="captchaComponent" context="register"></recaptcha3>
                                    <submit-button class="btn btn-rounded btn-yellow color-light py-1 px-5 mt-4 d-inline-block font-size-large" ref="btnSubmit" :loading="busy">Submit</submit-button>
                                    <div class="align-middle mt-5 float-md-right text-md-right">
                                        <div class="font-size-sm">Already Registered?
                                            <router-link :to="{name:'returning-player'}">Sign in</router-link>
                                        </div>
                                    </div>
                                </FormulateForm>
                                <div class="d-flex privacy-links justify-content-center mt-4">
                                    <a href="https://www.albertsonscompanies.com/policies-and-disclosures/privacy-policy/default.aspx" class="text-dark" target="_blank">Privacy Policy</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<style lang="scss" scoped></style>
<script>

const stateOptions = ['AL', 'AK', 'AS', 'AZ', 'AR', 'CA', 'CO', 'CT', 'DE', 'DC', 'FM', 'FL', 'GA', 'HI', 'ID', 'IL', 'IN', 'IA', 'KS', 'KY', 'LA', 'ME', 'MH', 'MD', 'MA', 'MI', 'MN', 'MS', 'MO', 'MT', 'NE', 'NV', 'NH', 'NJ', 'NM', 'NY', 'NC', 'ND', 'OH', 'OK', 'OR', 'PA', 'RI', 'SC', 'SD', 'TN', 'TX', 'UT', 'VT', 'VA', 'WA', 'WV', 'WI', 'WY'];
import vuexStore from '../store';
import Recaptcha3 from '../components/Recaptcha3';
import FormInputDOB from '../components/FormInputDOB';
import RouterLinkAda from '../components/router-link-ada.vue';


export default {
    name:       "new-player",
    components: {
        Recaptcha3,
        FormInputDOB,
    },
    beforeRouteEnter(to, from, next) {
        if (!vuexStore.get('auth/allowRegister')) {
            next({name: 'home'});
            return;
        }
        next();
    },
    data() {
        return {
            busy: false,
            //https://vueformulate.com/guide/validation/#customize-validation-messages
            registrationForm: [
                {
                    name:       'email',
                    label:      'Email (Must be a valid email address)*',
                    type:       'text',
                    validation: 'email',
                    value:      ''
                },
                {
                    name:           'email_confirm',
                    label:          'Verify Email*',
                    type:           'text',
                    validation:     'confirm',
                    validationName: 'Confirmation email',
                    value:          ''
                }, {
                    name:       'password',
                    label:      'Password*',
                    type:       'password',
                    validation: 'required|min:6,length',
                    value:      ''
                }, {
                    name:           'password_confirm',
                    label:          'Re-Type Password*',
                    type:           'password',
                    validation:     'confirm',
                    validationName: 'The password you entered',
                    value:          ''
                },
                {
                    name:           'first_name',
                    label:          'First Name*',
                    type:           'text',
                    validation:     'required',
                    validationName: 'First name',
                    value:          ''
                },
                {
                    name:           'last_name',
                    label:          'Last Name*',
                    type:           'text',
                    validation:     'required',
                    validationName: 'Last name',
                    value:          ''
                },
                {
                    name:           'address',
                    label:          'Address*',
                    type:           'text',
                    validation:     'required',
                    validationName: 'Address',
                    value:          ''
                },
                {
                    name:           'city',
                    label:          'City*',
                    type:           'text',
                    validation:     'required',
                    validationName: 'City',
                    value:          ''
                },
                {
                    name:           'state',
                    label:          'State*',
                    type:           'select',
                    options:        stateOptions,
                    validation:     'required',
                    validationName: 'State',
                    value:          ''
                },
                {
                    name:           'zip',
                    label:          'ZIP*',
                    type:           'text',
                    validation:     'required',
                    validationName: 'ZIP Code',
                    value:          ''
                },
                {
                    name:           'phone',
                    label:          'Contact Phone Number*',
                    type:           'text',
                    validation:     'min:10,length',
                    validationName: 'Contact phone number with area code',
                    value:          ''
                },
                {
                    // see vue-addons.js for component registration of FormInputChooseStore
                    name:           'store_id',
                    label:          'Where did you get your game ticket?*',
                    type:           'storeChooser',
                    validation:     'required',
                    validationName: "Store",
                    value:          ''
                },
                {
                    name:           'old_enough',
                    label:          'I am 18 years of age or older*',
                    validationName: 'age requirement',
                    type:           'checkbox',
                    validation:     'accepted',
                    value:          ''
                },
                {
                    name:  'newsletter',
                    label: 'I wish to receive future communication from my selected supermarket.',
                    type:  'checkbox',
                    value: ''
                },
                {
                    name:           'agree_to_terms',
                    label:          'I agree to the Rules & Regulations.*',
                    validationName: 'rules and regulations',
                    type:           'checkbox',
                    validation:     'accepted',
                    value:          ''
                }
            ],
            formErrors:       [],
            formData:         {},
            states:           stateOptions,
            formFieldErrors:  {}
        }
    },
    mounted() {
        this.formData = this.formDefaultValues;
    },
    computed: {
        formDefaultValues() {
            let data = {};
            if (this.$config.env === 'local') {
                try {
                    // data = require('../defaults/new-player-test-data.json');
                    console.log('using test data:', data);
                } catch (err) {
                }
            }
            return data;
        }
    },
    methods:  {
        async submit(data) {
            this.busy = true;
            if (this.$refs.captchaComponent) {
                data['captcha'] = await this.$refs.captchaComponent.getToken();
            }
            console.log(data);
            let response = await this.$http.post('user/register', data).catch(({response}) => {
                this.busy = false;
                if (response.data.error) {
                    this.formFieldErrors = response.data.error;
                    this.formErrors = [response.data.error.errorMessage];
                }
                console.error('formFieldErrors:', this.formFieldErrors)
            });
            if (response) {
                this.$root.$children[0].loginUserAndEnterCodes(response.data);
            } else {
                if (this.$refs.captchaComponent) {
                    this.$refs.captchaComponent.reset();
                }
            }
        },
        test() {
            this.formData = this.testData;
        }
    },

};
</script>
