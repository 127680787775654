import Vue from 'vue';

let id = 1;
export function ModalInstance(name, opts) {
    this.modalType = name;
    this.id = id++;
    this.opts = Object.assign({}, opts);
    this.zIndex = ModalService._stack.length;
	this._eventContainer = $({});
	this.activeElement = document.activeElement;
};

Object.assign(ModalInstance.prototype, {
    on: function(evt, cb) {
        return ModalService.on(evt, cb, this);
    },
    off: function(evt, cb) {
        return ModalService.off(evt, cb, this);
    },
    trigger: function(evts) {
        let payload = Array.prototype.slice.call(arguments, 1);
        evts = evts.split(' ').map(evt => evt.trim()).filter(evt => evt);

        evts.forEach((evt) => {
            let e = $.Event(evt);
            e.modal = this;

            this._eventContainer.trigger(e, payload);
            if (!e.isPropagationStopped()) {
                ModalService._eventContainer.trigger(e, payload);
            }
        });

        return this;
    },
    destroy() {
        ModalService.destroy(this);
        return this;
    },
    startOver() {
        if (ModalService._stack.indexOf(this) === -1) {
            ModalService._stack.push(this);
        }
        return this;
	},
	setReturnElement: function(el) {
		this.activeElement = el;
		return this;
	}
});

export const ModalService = {
    _allowMultiple: true,
    _modals: {},
    _eventContainer: $({}),

    setAllowMultiple(allow) {
        this._allowMultiple = allow;
    },

    getVisibleModals() {
        return this._stack;
    },

    create(name, opts = {}, openNow = true) {
        if (!this._allowMultiple && this._stack.length) {
            throw 'setAllowMultiple is currently set to false';
        }

        // if (!this._modals[name]) {
        //     throw `Modal ${name} cannot be found. Please register it with registerModalComponent`;
        // }

        let modalInst = new ModalInstance(name, opts);
        if (openNow) {
            this._stack.push(modalInst);
        }

        return modalInst;
    },

    destroy(modalInst) {
        if (modalInst) {
			let ndx = this._stack.indexOf(modalInst);
			if (ndx >= 0) {
				this._stack.splice(ndx, 1);
			}
        }
        else {
            modalInst = this._stack.pop();
		}
	},
	
	close(modalInst) {
        if (!modalInst) {
			if (!this._stack.length) {
				return false;
			}

			modalInst = this._stack[this._stack.length - 1];
		}

		modalInst.trigger('close');
		return true;
	},

    registerModalComponent(comp) {
        this._modals[comp.name] = comp;
    },

    _getModalComponent(name) {
        if (this._modals[name]) {
            return this._modals[name];
        }
        else {
            return () => {
                return import(/* webpackMode: "eager" */ '../components/modals/' + name).then((component) => {
                    return this._modals[name] = component.default ? component.default : component;
                }, () => {
                    console.error(`The modal ${name} could not be loaded!`);
                })
            };
        }
    },

    on(evt, cb, bound) {
        bound = bound || ModalService;
        let args = [evt];
        if (cb) {
            args.push(cb);
        }
        bound._eventContainer.on.apply(bound._eventContainer, args);
        return bound;
    },
    off(evt, cb, bound) {
        bound = bound || ModalService;
        let args = [evt];
        if (cb) {
            args.push(cb);
        }
        bound._eventContainer.off.apply(bound._eventContainer, args);
        return bound;
    }
};

Vue.util.defineReactive(ModalService, '_stack', []);

// Default close behavior
ModalService.on('close', (e) => {
    Vue.nextTick(() => {
        if (!e.isDefaultPrevented()) {
            ModalService.destroy(e.modal);
        }
    });
});

ModalService.on('hidden', (e) => {
	if (e.modal.activeElement instanceof HTMLElement) {
		Vue.nextTick(() => {
			if (!e.isDefaultPrevented()) {
				if (navigator.userAgent.match(/iPad|iPhone/i)) {	// all iPhone/iPad browsers, because VoiceOver sucks
					setTimeout(function() {
						$(e.modal.activeElement).focus();
					}, 100);
				}
				else {
					$(e.modal.activeElement).focus();
				}
			}
		});
	}
});

window.modalService = ModalService;
