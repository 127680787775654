<template>
	<div class="main main-account-error">

		<div class="container">

			<div class="page-header ticket">
				<lang id="account-error-header">
					<h1 class="page-title text-center text-uppercase font-size-h3">Error</h1>
				</lang>
			</div>
			<div class="row">
				<div class="col-lg-6 mx-auto">
					<div class="box box-blue h-100">
						<div class="text-center">
              				<div v-for="(message, idx) in errorMessages">{{ message }}</div>
							<router-link :to="{name:'play'}" class="btn btn-primary btn-block mt-4">Continue</router-link>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
    export default {
        name:       'error',
        computed: {
            errorMessages() {
                return this.$store.get('auth/messages')
            }
        },


        data() {
            return {}
        },

        methods: {},

    }
</script>
