<template>
    <div v-if="html">
        <slot>
            <div class="font-size-small">Game starts in:</div>
        </slot>
        <div class="h6">{{ html }}</div>
    </div>
</template>

<script>
import {getTime, parseISO} from 'date-fns';

export default {
    name:  "countdown",
    props: {
        date:   String,
        format: {
            type:    String,
            default: 'dhms'
        }
    },
    data() {
        return {
            timerHandle:   null,
            currentDateMs: Date.now()
        }
    },
    mounted() {
        this.timerHandle = setInterval(() => {
            this.currentDateMs = Date.now()
        }, 1000);
    },
    beforeDestroy() {
        clearInterval(this.timerHandle);
    },
    computed: {
        targetDateMs() {
            // return getTime(parseISO('2021-01-21 17:00:20'));
            return getTime(parseISO(this.date));
        },
        html() {
            let remain = Math.floor((this.targetDateMs - this.currentDateMs) / 1000), trimZeroes=true
            if (remain === 0) return '0s';
            if (remain <0) return null;
            let pieces = [
                ['d', 86400],
                ['h', 3600],
                ['m', 60],
                ['s', 1]
            ].map(([label, seconds]) => {
                let qty = Math.floor(remain / seconds);
                remain -= (seconds * qty);
                if (trimZeroes && !qty){
                    return null;
                }
                if (this.format.indexOf(label) >= 0) {
                    // trimZeroes = false;
                    return qty+label;
                }
                return null;
            })
            return pieces.join(' ');
        }
    }
}
</script>

<style scoped>

</style>
