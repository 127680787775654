import Vue from 'vue';

export default function(methods, define = false) {
	let definition = {
		inserted: (el, binding) => {
			el._ada = [];
	
			methods.forEach((method) => {
				if (method.isValid(el, binding)) {
					el._ada.push(new method(el, binding));
				}
			});
		},
		update: (el, binding) => {
			if (el._ada) {
				el._ada.forEach((obj) => {
					if (obj.update) {
						obj.update(binding);
					}
				});
			}
		},
		componentUpdated: (el, binding) => {
			if (el._ada) {
				el._ada.forEach((obj) => {
					if (obj.componentUpdate) {
						obj.componentUpdate(binding);
					}
				});
			}
		},
		unbind: (el, binding) => {
			if (el._ada) {
				el._ada.forEach((obj) => {
					if (obj.destroy) {
						obj.destroy();
					}
				});
	
				delete el._ada;
			}
		}
	};

	if (define) {
		Vue.directive('ada', definition);
	}
	else {
		return definition;
	}
}