(function($) {

	var focussableSelector = 'a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]';

	function handleKeydown($el, e, focussable) {
		if (e.keyCode == 9) {
			e.preventDefault();

			var dir       = e.shiftKey ? -1 : 1,
				focusable = $el.find(focussable).filter(':visible'),
				ndx       = focusable.index(document.activeElement);
			ndx = (ndx + dir + focusable.length) % focusable.length;
			focusable[ndx].focus();
		}
	}

	$.fn.ADANavigation = function(focussable, isActiveCb) {
		var $els = this;

		$els.on('keydown', function(e) {
			if (!isActiveCb || isActiveCb(e)) {
				handleKeydown($els, e, focussable || focussableSelector);
			}
		});

		return this;
	};

	$.ADANavigation = function(selector, focussable, isActiveCb) {
		$(document).on('keydown', selector, function(e) {
			if (!isActiveCb || isActiveCb(e)) {
				handleKeydown($(this), e, focussable || focussableSelector);
			}
		});
	}

})(window.jQuery);
