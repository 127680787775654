// import Route from 'vue-routisan'
import Route from '../utils/VueRoutisan';
import store from '@/store'
import Vue from 'vue';
import config from '../startup/config';

//#region helpers


// define view resolver
Route.setViewResolver((component) => {
	if (typeof component == 'string') {
		let componentObj = require('../views/' + component);
		return componentObj.default ? componentObj.default : componentObj;
	} else {
		return component;
	}
});

// guards here
const homeGuard = (to, from, next) => {
	// redirect old references to /#/contact to /contact
	if (!process.appVersion) {
		if (from.hash !== '#/contact' && to.hash === '#/contact') {
			next('/contact');
			return;
		}
	}
	// let session = store.get('auth/sessionData');
	let defaultPage = store.get('auth/defaultPlayerLandingPageName');
	if (defaultPage === to.name) {
		next();
		return;
	}
	let g = authGuard(null, defaultPage);
	g(to, from, next);
}

const authGuard = (anonRedirect = 'home', authRedirect = null) => (to, from, next) => {
	let redirect = store.get('auth/isLoggedIn') ? authRedirect : anonRedirect;
	if (redirect == null) {
		next();
	} else {
		if (typeof redirect == 'string') {
			next({name: redirect});
		} else {
			next(redirect);
		}
	}
};

const agreeToTermsGuard = (to, from, next) => {
	if (!store.get('auth/mustAgreeToJ4UTerms') && to.path !== '/') {
		next('/');
	} else {
		next();
	}
};

const loginRegisterGuard = (to, from, next) => {
	if (!store.get('auth/allowLoginOrRegister')) {
		let defaultPage = 'home';
		if (store.get('auth/isLoggedIn')) {
			defaultPage = store.get('auth/defaultPlayerLandingPageName');
		}
		next({name: defaultPage});
		return;
	}
	next();
}
//#endregion

// define routes

Route.view('/', 'returning-player').options({
	name:        'home',
	meta:        {
		loggedIn:      false,
		sort:          0,
		menuTitle:     'Home',
		documentTitle: 'Returning Player'
	},
	beforeEnter: homeGuard
});

// Route.view('/choose-store/:banner?', 'choose-store').options({
// 	name:        'play',
// 	meta:        {
// 		inMenu: ()=>store.get('auth/allowLoginOrRegister'),
// 		loggedIn:      false,
// 		sort:          1,
// 		menuTitle:     'Play',
// 		documentTitle: 'Choose a Store'
// 	},
// 	beforeEnter:(to, from, next) => {
// 		if (!store.get('auth/allowLoginOrRegister')){
// 			let defaultPage = 'home';
// 			if (store.get('auth/isLoggedIn')){
// 				defaultPage = store.get('auth/defaultPlayerLandingPageName');
// 			}
// 			next({name:defaultPage});
// 			return;
// 		}
// 		next();
// 	}
// });

Route.view('/new-player', 'new-player').name('new-player')
	.options({
		beforeEnter: loginRegisterGuard,
		meta:        {
			inMenu:        () => store.get('auth/allowRegister') && !store.get('auth/isLoggedIn'),
			documentTitle: 'New Player'
		}
	});

Route.view('/returning-player', 'returning-player').name('returning-player')
	.options({
		beforeEnter: loginRegisterGuard,
		meta:        {
			inMenu:        () => store.get('auth/allowLogin') && !store.get('auth/isLoggedIn'),
			documentTitle: 'Returning Player'
		}
	});

Route.view('/faq', 'faq').name('faq').meta({
	sort:          3,
	menuTitle:     'FAQ',
	documentTitle: 'Frequently Asked Questions'
});
Route.view('/rules/:type?/:lang?', 'rules').name('rules').meta({
	sort:          4,
	menuTitle:     'Rules',
	documentTitle: 'Rules'
});

Route.view('/winners-vue', 'winners').name('winners.vue').meta({
	sort:          4,
	inMenu:        false,
	menuTitle:     'Winners',
	documentTitle: 'Winners'
});
Route.view('/match', 'match').name('match').meta({
	sort:          5,
	inMenu:        false,
	menuTitle:     'Match',
	documentTitle: 'Match'
});

Route.view('/reset-password/:token', 'reset-password').name('reset-password').meta({
	inMenu:        false,
	documentTitle: 'Reset Password'
});

Route.view('/forgot-password', 'forgot-password').name('forgot-password').meta({
	inMenu:        false,
	documentTitle: 'Forgot Password'
});


Route.group({meta: {inMenu: false}}, () => {
	Route.view('/error', 'error').name('error').meta({documentTitle: 'Error'});
	Route.view('/agree-to-terms', 'account').options({
		name:        'agree-to-terms',
		meta:        {documentTitle: 'Agree to Terms'},
		beforeEnter: agreeToTermsGuard
	})
	// Route.view('/privacy-policy', 'privacy').name('privacy-policy').meta({documentTitle: 'Privacy Policy'});

	// Route.view('/terms-and-conditions', 'terms-and-conditions').name('terms-and-conditions').meta({documentTitle: 'Terms & Conditions'});
	Route.view('/contact/:step?', 'contact').name('contact-us').meta({documentTitle: 'Contact Us'});
	// if (!process.appVersion) {
		// Route.view('/download-app', 'download-app').name('download-app').meta({documentTitle: 'Download the app'})
	// }
});

Route.group(
	{
		beforeEnter: [
			authGuard(),
			(to, from, next) => {
				if (to.name !== 'account' && store.get('auth/mustUpdateProfile')) {
					next({name: 'account'});
				} else {
					next();
				}
			}
		],
		meta:        {loggedIn: true}
	},
	() => {
		Route.view('/enter-code/:codeToEnter?', 'enter-code').name('enter-code').meta({
			inMenu:        () => store.get('auth/allowEnterCodes'),
			sort:          0,
			menuTitle:     'Enter Code',
			documentTitle: 'Enter Codes',
		});
		Route.view('/history', 'history').name('code-history').meta({
			inMenu:        () => store.get('auth/allowCodeHistory'),
			sort:          2,
			menuTitle:     'Code History',
			documentTitle: 'Code History'
		});

		// Removed prizes page because layout undefined
		// Route.view('/prizes', 'prizes').name('prizes').meta({
		// 	inMenu:        true,
		// 	menuTitle:     'Prizes',
		// 	documentTitle: 'Prizes'
		// });
		// Route.view('/winners', 'winners').name('winners').meta({inMenu:true, menuTitle: 'Winners', documentTitle: 'Winners'});
		Route.view('/account', 'account').name('account').meta({
			inMenu:        false,
			menuTitle:     'Account',
			documentTitle: 'Account',
			sort:          99
		});
		//
		// Route
		// 	.view('/track-markers', 'track-markers')
		// 	.options({
		// 		name: 'track-markers',
		// 		meta: {
		// 			sort:          1,
		// 			menuTitle:     'Track Markers',
		// 			documentTitle: 'Track Game Markers'
		// 		}
		// 	})
		// 	.guard([
		// 		(to, from, next) => {
		// 			store
		// 				.dispatch('auth/refreshUser')
		// 				.then(() => {
		// 					next();
		// 				});
		// 		}
		// 	]);
	}
);

Route
	.view('/logout')
	.options({
		name: 'logout',
		meta: {
			loggedIn:  true,
			menuTitle: 'Logout',
			sort:      9999
		}
	})
	.guard([
		(to, from, next) => {
			Vue.$log.info('[route: logout]');

			// logout User which will reset our local session data to a logged-out user
			// codes: logout and redirect to ripitandwinit.com/logout
			// guest: logout and redirect to /
			store.dispatch('auth/logoutUser').then(() => {
				let redirectUrl = '/';

				if (config.usingLoggedInDomain) {
					let redirect = [
						location.protocol,
						'//',
						config.guestDomain
					];
					if (location.port) {
						redirect.push(`:${location.port}`)
					}
					redirect.push('/logout');
					redirectUrl = redirect.join('');
				}
				if (!config.domainCheckRequired) {
					Vue.$log.info('[route: logout] app: redirecting to home');
					next({name: 'home'});
				} else {
					Vue.$log.info('[route: logout] redirecting to:', redirectUrl);
					location.href = redirectUrl;
				}
			});
		}
	]);

Route.view('*', 'external-page').name('external-page').meta({
	inMenu: false,
});
// Route.redirect('*', '/');

export default Route.all();
