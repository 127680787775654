// Polyfills
import 'core-js/actual/promise';
import 'core-js/actual/string/includes';
import 'core-js/actual/array/includes';

// Application start
import './startup/libraries'
import './startup/vue-addons'
import './startup/vue-filters'
import './startup/global-components'

import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'

// Is this a non www. domain AND not the app?
if (location.hostname.indexOf('timbersshopandscore') >= 0) {
  if (!/^www|qa\./.test(location.hostname)) {
    location.href = `https://www.${location.hostname}${location.pathname}${location.hash}`;
  }
}

Vue.config.productionTip = false;

let startup = function () {
  new Vue({
	router,
	store,
	render: (h: any) => h(App)
  } as any).$mount('#app')
};

startup();
