<template>
	<modal v-bind="modalOpts">
		<h5 class="modal-title text-center" slot="header" v-html="title" slot-scope="{id}" :id="id"></h5>
		<div slot="body" v-html="message" class="text-center"></div>
		<div slot="footer" class="btn-block">
			<button type="button" class="btn btn-primary btn-block m-1" @click="closeModal" v-ada>Close</button>
		</div>
	</modal>
</template>

<script>
    export default {
        name:     'genericCodeEntryMessage',
        props:    {
			modal: {
				type: Object,
				required: true
			},
            title:   String,
            message: String,
            theme:   String,
            opts:    Object
        },
        computed: {
            modalOpts() {
                let defaults = {
                    classes: []
                };

                return $.extend(defaults, this.opts || {});
            }
        },
		methods:{
            closeModal() {
				this.modal.trigger('close');
            }
		}
    }
</script>
