import {EventBus} from './EventBus'
import store from 'store2';

/**
 * Apps will use nativestorage, everything else use localStorage
 * @type {{getItem(*=): Promise<unknown>, setItem(*=, *=, *): Promise<unknown>}}
 */
const myNativeStorage = {
	getItem(name) {
		return new Promise((resolve) => {
			if (process.appVersion) {
				EventBus.$when('deviceready', () => {
					window.NativeStorage.getItem(name, (data) => {
						resolve(data);
					}, (err) => {
						// error: nativestorage
						resolve();
					});
				});
			} else {
				resolve(store.get(name));
			}

		});
	},

	setItem(name, value, opts) {
		return new Promise((resolve, reject) => {
			if (process.appVersion) {
				EventBus.$when('deviceready', () => {
					window.NativeStorage.setItem( name, value, (data) => {
						resolve(data);
					}, (err) => {
						// error: nativestorage
						reject();
					});
				});
			} else {
				resolve(store.set(name, value));
			}

		});
	},

	removeItem(name, value, opts) {
		return new Promise((resolve, reject) => {
			if (process.appVersion) {
				EventBus.$when('deviceready', () => {
					window.NativeStorage.remove( name, (data) => {
						resolve(data);
					}, (err) => {
						reject();
					});
				});
			} else {
				resolve(store.remove(name));
			}

		});
	}
};

export default myNativeStorage;
