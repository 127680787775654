<template>
    <div class="container-fluid pb-4">
        <div class="row page-title">
			<div class="col-12 d-flex align-items-center justify-content-center">
				<h1 class="text-center text-light py-5 m-0">Enter Your Code To Play The Game!</h1>
			</div>
		</div>
        

        <div class="container">
            <div class="bg-gray py-3 pl-3 pr-0 py-lg-5 pl-lg-5 mt-3 mb-5 mx-0 mx-lg-3 rounded text-dark">
                <div class="" v-if="!missingPhoneNumber">
                    <div class="row">

                        <div class="col-lg-6">
                            <form ref="form" @submit.prevent="submitCode" class="p-4 p-md-5">
                                <div class="form-group">
                                    <div class="alert alert-info text-center" v-if="message">{{ message }}</div>
                                    <label for="code" class="h5 text-center d-block text-dark">Enter a code below</label>
                                    <input type="text"
                                           v-model="code"
                                           id="code"
                                           ref="codeInput"
                                           class="form-control code-input text-center text-uppercase"
                                           autocomplete="off"
                                           spellcheck="false"
                                           :placeholder="$store.getters.getLang('enter-code-code-placeholder', 'Enter game code here')">
                                    <small class="helper-text d-block text-center">Dashes optional</small>
                                </div>
                                <div class="scanButtons">
                                    <submit-button type="submit"
                                            class="btn btn-yellow btn-block btn-submit-code font-size-large text-shadow-dark rounded py-1"
                                            data-style="zoom-out"
                                                  :loading="busy"
                                            @click="returnFocusEl = $event.target">Submit Code
                                    </submit-button>
                                </div>
                                <template v-if="showScanButtons" class="scanButtons">
                                    <button class="btn btn-primary btn-block mt-3 btn-scan" type="button" @click="scanCode($event)" v-ada>Scan Code
                                    </button>
                                </template>

                            </form>
                        </div>
                        <div class="col-lg-6">
                            <div class="h-100 d-flex justify-content-center">
                                <img src="../assets/images/win-up-to.png" class="img-fluid win-t-shirt" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="container">
            <div class="green-gradient-vertical-5050 p-3 py-lg-4 px-lg-5 mt-3 mb-5 mx-0 mx-lg-3 rounded text-light text-center font-special text-shadow-dark">
                <p class="h3 font-weight-normal">Weekly Sweepstakes Drawings</p>
                <p class="font-size-large text-uppercase color-greenalt">Codes Played Enter You Into Our Sweepstakes Drawings For:</p>
                <div class="row">
                    <div class="col-4">
                        <img src="../assets/images/win-car.png" class="img-fluid" alt="" />
                        <p class="font-size-large text-uppercase">Grand Prize Of A 2025 Toyota Camry XSE</p>
                    </div>
                    <div class="col-4">
                        <img src="../assets/images/win-timbers.png" class="img-fluid" alt="" />
                        <p class="font-size-large text-uppercase">Cool Timbers Experiences And Swag</p>
                    </div>
                    <div class="col-4">
                        <img src="../assets/images/win-giftcards.png" class="img-fluid" alt="" />
                        <p class="font-size-large text-uppercase">Grocery Gift Cards, From $250 Up To $5,200!</p>
                    </div>
                </div>
                <p class="font-size-large text-uppercase win-border-top pt-4 m-0">Plus Other Great Prizes (See Official Rules For Details)</p>
            </div>
        </div>

        <div class="container pt-5">
            <page-sub-section :cols="['history-left', 'faq', 'rules']"></page-sub-section>
        </div>

        <tracker v-if="$config.useTrackers" name="Enter Code"></tracker>

    </div>
</template>
<style lang="scss" scoped>
.slider-box {
    padding: 40px 40px 75px;
}

.box-blue {
    @media(min-width: $lg) {
        // height: 100%;
        flex-grow: 2;
    }
}

legend {
    font-size: $size18;
}
.line-1 {
    line-height: 1.2;
    text-transform: uppercase;
    color: $light;
}

.line-2 {
    line-height: 1.2;
    text-transform: uppercase;
    color: $light;
    sup {
        top: -.75rem;
        font-size: 60%;
    }
}
.win-border-top {
    border-top: 1px solid $yellow;
}
</style>

<script>
import Raven from "raven-js";
import store from 'store';

import vuexStore from '../store';
import * as ladda from "ladda";

import tracker from "../components/trackers/General";
import checkboxCustom from '../components/checkbox-custom';

export default {
    name:       "enter-code",
    components: {
        tracker,
        checkboxCustom
    },
    data() {
        return {
            busy:false,
            code:                null,
            codeScanned:         false,
            message:             null,
            counter:             0,
            showSlider:          true,
            scanWithFrontCamera: false,
            slickOptions:        {
                dots:      true,
                infinite:  true,
                speed:     500,
                fade:      true,
                cssEase:   "linear",
                prevArrow: '<button type="button" class="slick-prev"><span class="sr-only">Previous Slide</span></button>',
                nextArrow: '<button type="button" class="slick-next"><span class="sr-only">Next Slide</span></button>'
            },

            returnFocusEl: null,
            scanWithTorch: false
        };
    },

    computed: {
        isDev() {
            return this.$config.env === 'local'
        },
        lastPlayedCode() {
            if (this.isDev) {
                return this.$store.get('auth/lastPlayedCode');
            }
            return null;
        },
        sliderContent() {
            let data = this.$store.get("auth/user");
            let sliders = data && data.sliders || [];
            return sliders.filter(slide => {
                return slide.where.indexOf('code-entry') >= 0;
            });
        },
        missingPhoneNumber() {
            return this.$store.get("auth/missingPhoneNumber");
        },
        match3GameInProgress() {
            return this.$store.get("auth/user@playMatch3Game");
        },
        showScanButtons() {
            return this.$store.get('global/isApp');
        },
        isIos() {
            if (typeof device !== 'undefined') {
                return device.platform === 'iOS';
            }
            return false;
        }
    },
    mounted() {
        this.watchConnectivity(true);

        this.$watch(
            "missingPhoneNumber",
            to => {
                if (to) {
                    this.$modalService.create("codeEntry/getPhoneNumber", {
                        backdrop: "static"
                    });
                }
            },
            {immediate: true}
        );

        if (this.$store.get("global/codeScanned")) {
            this.code = this.$store.get("global/codeScanned");
            this.$store.set("global/codeScanned", null);
            this.submitCode({
                scanned: true
            });
        }


        if (/\.local$|qa\./.test(location.hostname) || location.port === '8080') {
            if (this.$route.params.codeToEnter) {
                this.$nextTick(() => {
                    this.submitCode({code: this.$route.params.codeToEnter});
                })
            }
        }
    },
    beforeRouteEnter(to, from, next) {
        if (!vuexStore.get('auth/allowEnterCodes')) {
            let nextPage = vuexStore.get('auth/defaultPlayerLandingPageName');
            next({name: nextPage});
            return;
        }
        let playingMatch3 = vuexStore.get("auth/user@playMatch3Game");
        if (playingMatch3) {
            next({name: 'match'});
            return;
        }

        next();
    },
    beforeDestroy() {
        let modal = $(this.$refs.slotmodal);
        if (modal.hasClass("in")) {
            modal.modal("hide");
        }

        modal.off("shown.bs.modal hidden.bs.modal");

        this.watchConnectivity(false);
    },

    methods: {

        testCouponModal() {
            this.code = this.lastPlayedCode;
            this.$nextTick(() => {
                $('.btn-submit-code').click();
            });
        },
        enableScanButton(enable = true) {
            let scanBtn = $(this.$el).find('.btn-scan');
            if (enable) {
                this.softDisabled(scanBtn, false);
                this.$EventBus.$off("backbutton", this.cancelBackButton);
            } else {
                this.$EventBus.$on("backbutton", this.cancelBackButton);
                this.softDisabled(scanBtn, true);
            }
        },
        scanComplete(result) {
            if (result.cancelled) {
                this.enableScanButton();
                plugins.toast.showShortTop("Scan cancelled");
                return;
            }

            this.code = result.text;
            this.submitCode({
                scanned: true
            });
        },
        scanFailed(result) {
            console.log('scan error:', result);
            let cameraError = typeof result.message === 'string' ? result.message : result;
            let errorMessageToUser = null;
            if (/illegal access/i.test(cameraError)) {
                errorMessageToUser = 'Camera disabled: Please enable it in your settings app';
            } else if (/access to the camera has been prohibited/i.test(cameraError)) {
                errorMessageToUser = 'Camera disabled: Please enable it in your settings app';
            } else if (/scan is already in progress/i.test(cameraError)) {
                errorMessageToUser = 'Scan already in progress';
            }
            if (errorMessageToUser) {
                // todo open the settings page
                // @see https://www.npmjs.com/package/cordova-plugin-x-toast#4-usage
                // @see https://github.com/dpa99c/cordova-diagnostic-plugin#switchtosettings
                plugins.toast.showShortTop(errorMessageToUser);
            } else {
                Raven.captureException(
                    typeof error === "string" ? new Error(error) : error
                );
                gaU("send", "event", "Scanner", "Scan Failed");
            }
            this.enableScanButton();
        },
        scanCode(e) {
            this.returnFocusEl = e.target;
            let scanBtn = $(this.$el).find('.btn-scan');

            if (this.softDisabled(scanBtn)) {
                return;
            }

            this.enableScanButton(false);

            try {
                cordova.plugins.barcodeScanner.scan(
                    this.scanComplete,
                    this.scanFailed,
                    {
                        preferFrontCamera:     this.scanWithFrontCamera, // iOS and Android
                        torchOn:               this.scanWithTorch, // Android, launch with the torch switched on (if available)
                        showFlipCameraButton:  false, // iOS and Android
                        showTorchButton:       false, // iOS and Android
                        saveHistory:           true, // Android, save scan history (default false)
                        prompt:
                                               "Trouble Scanning? Try placing a white piece of paper behind your game piece.", // Android
                        resultDisplayDuration: 500, // Android, display scanned text for X ms. 0 suppresses it entirely, default 1500
                        formats:               "CODE_128, UPC_A, UPC_E", // default: all but PDF_417 and RSS_EXPANDED
                        //                        orientation : "landscape", // Android only (portrait|landscape), default unset so it rotates with the device
                        disableAnimations:  true, // iOS
                        disableSuccessBeep: true // iOS and Android
                    }
                );
            } catch (err) {
                // default to showing a "permissions" error
                console.error(err);
                this.scanFailed("illegal access");
            }
        },
        cancelBackButton(opts) {
            opts.cancel = true;
        },
        validateCode(code) {
            let result = {
                code: code.replace(/\s+|-/g, ""),
                type: "game"
            };

            /*if (result.code.match(/^(?:S|[123456789ABCDFGHKLMNPRTVWXYZ])[123456789ABCDFGHKLMNPRTVWXYZ]{15}$/i)) {
                                          result.type = 'game';
                                      }
                                      else*/
            /* if (result.code.match(/^11[A-Z]{4}\d{4}$|^[RO][A-Z\d]{6}\w$/i)) {
                result.type = "inventory";
            } */

            return result;
        },

        employeeCodeEntered: function (code) {
            this.$modalService.create("codeEntry/generic", {
                title:   "Employee Code",
                message: `${code} is an employee code and cannot be played on this account`
            })
                .setReturnElement(this.returnFocusEl);
        },
        customerCodeEntered: function (code) {
            this.$modalService.create("codeEntry/generic", {
                title:   "Customer Code",
                message: `${code} is a customer code and cannot be played on this account`
            })
                .setReturnElement(this.returnFocusEl);
        },

        wonPrize: function (info) {
            // custom message override:

            if ("modal" in info) {
                let modalName = info.modal.messageType || 'generic';
                this.$modalService.create(`codeEntry/${modalName}`, info.modal).setReturnElement(this.returnFocusEl);
                return;
            }

            return new Promise((resolve) => {

                // prizeWon modal is a default message that probably should never appear, as
                // we now show the slot machine instead
                let prizeNotification = "prizeWon";
                if (info.prize.messageType) {
                    prizeNotification = info.prize.messageType;
                }

                // slot machine wants an array of numbers:
                if (info.slot_machine) {
                    info.slot_machine = info.slot_machine.split(",");
                    prizeNotification = "slotMachine";
                }
                this.$modalService
                    .create(`codeEntry/${prizeNotification}`, {info})
                    .setReturnElement(this.returnFocusEl)
                    .on("close", () => {
                        // Allow the promise in submitCode to continue
                        resolve();
                    });
            });
        },

        notAWinner:                      function (code) {
            // this.message = `Sorry, ${code} is not a winning code.`
            this.$modalService.create("codeEntry/generic", {
                title:   "Try Again",
                message: `Your code ${code} is not a winning code. Thanks for playing!`
            })
                .setReturnElement(this.returnFocusEl);
        },
        alreadyEnteredThisCode:          function (code) {
            // this.message = `You have already entered the code ${code}`
            this.$modalService.create("codeEntry/generic", {
                title:   "Duplicate Code",
                message: `Your code ${code} has already been submitted. Please try again.`
            })
                .setReturnElement(this.returnFocusEl);
        },
        alreadyEnteredThisCodeContactCS: function (code) {
            this.$modalService.create("codeEntry/generic", {
                title:   "Invalid Code",
                message: `${code} has previously been entered by another player - please contact Customer Service`
            })
                .setReturnElement(this.returnFocusEl);
        },
        mustAgreeToTerms:                function (code) {
            this.code = code;
            this.$modalService
                .create("codeEntry/customerToEmployee")
                .setReturnElement(this.returnFocusEl)
                .on("continue", () => {
                    this.submitCode({
                        code,
                        agree: true
                    });
                });
        },
        lockForm(disable) {
            let els = $(this.$refs.codeInput)
                .add($(this.$el).find(':submit'))
                .add($(this.$el).find('form'));

            this.softDisabled(els, disable);
        },
        submitCode(opts) {
            let form = $(this.$el).find('form');
            if (this.softDisabled(form)) {
                return;
            }

            if (this.$store.get("global/offline")) {
                this.$modalService.create("offlineModal", {
                    title:   "App Offline",
                    message: "Please reconnect to the internet..."
                })
                    .setReturnElement(this.returnFocusEl);

                return;
            }

            opts = $.extend({agree: false}, opts);

            if (typeof opts.scanned === "boolean") {
                this.codeScanned = true;
            }
            if ("code" in opts) {
                this.code = opts.code;
            }
            this.message = null;

            // Check code type
            let codeType = this.validateCode(this.code || "");

            let data = {
                code:    codeType.code,
                scanned: this.codeScanned ? 1 : ""
            };

            this.lockForm(true);

            let promise = Promise.resolve();
            if (codeType.type === "game") {		// This is always the type. See validateCode
                data = $.extend(data, {
                    type:           "Albertsons",
                    agree_to_terms: opts.agree
                });
                this.busy=true
                promise = this.enterGameCode(data);
            }
            let unlockForm = () => {
                this.busy=false
                this.lockForm(false);
                this.code = "";
                this.codeScanned = false;
                this.enableScanButton();
            };
            promise.then(unlockForm, unlockForm);
        },

        enterGameCode(data) {
            this.message = null;
            return new Promise((resolve, reject) => {
                this.$store.set('auth/lastPlayedCode', data.code);

                // todo: emit an event, then listen for that event somewhere else, and submit this tracking event
                // track event
                gtag('event', 'conversion', {
                    'allow_custom_scripts': true,
                    'send_to':              'DC-10025253/timbe0/timbe0+standard'
                });

                fbq('track', 'Timbers_SaveScore_CodeSubmit');

                this.$http
                    .post("/game/enterCode", data)
                    .then(response => {
                        // if agreed to terms to change usertype, or a prize is won, we must refresh the userdata:
                        if (data.agree_to_terms || response.data.result === 'W') {
                            let delay = response.data.slot_machine ? 3000 : 0;
                            setTimeout(() => {
                                this.$store.dispatch("auth/refreshUser").then(() => {
                                    this.showSlider = false;
                                    this.$nextTick(() => {
                                        this.showSlider = true;
                                    });
                                })
                            }, delay);
                        }
                        let info = response.data;
                        if (info.result !== 'M') {
                            resolve();
                        }
                        switch (info.result) {
                            case "I":
                                return this.invalidCode(info);
                            case "C":
                                return this.customerCodeEntered(info.code);
                            case "T":
                                return this.employeeCodeEntered(info.code);
                            case "U":
                                return this.mustAgreeToTerms(info.code);
                            case "D":
                                return this.alreadyEnteredThisCode(info.code);
                            case "X":
                                return this.alreadyEnteredThisCodeContactCS(info.code);
                            case "M":
                                // start Match 3 game
                                // every page navigation results in a ping to /user, so this setter is not needed.
                                // this.$store.set('auth/setMatch3Game', info.match3GameData);
                                this.$router.replace({name: 'match'});
                                return 'M';
                            // these three will need extra work done
                            case "V":
                                return this.notAWinner(info.code);
                            case "Z":
                                return this.showMessage(info);
                            case "W":
                                return this.wonPrize(info);
                        }
                    }, (error) => {
                        // unlock the form and clear out the submitted code
                        resolve();
                        // Error when sending the request
                        if (error.response && error.response.status === 401) {
                            // this.$store.set('auth/ready', false);
                            this.$store.dispatch('auth/refreshUser').then((user) => {
                                if (user) {
                                    this.$router.replace({name: 'enter-code'});
                                } else {
                                    this.$router.replace({name: 'play'});
                                }
                            });
                            return;
                        }
                        if (!error.response || (error.response && error.response.status >= 500)) {
                            this.message = "Sorry, your request can not be processed at this time. Please try again in a few minutes.";
                        } else {
                            this.message = this.errorResponseToArray(error).join('<BR>')
                        }
                    })
                    .catch(error => {
                        // Error from trying to handle the response
                        this.message = "We processed the request but have run into a problem displaying the result.";
                    })
            });
        },
        showMessage(info) {
            this.$modalService.create("codeEntry/generic", info).setReturnElement(this.returnFocusEl);
        },
        invalidCode(response) {
            let modal = {
                title:   response.title || "Invalid Code",
                message: response.message || `<p>Your code ${response.code} is not a valid code. Please try again.</p>`
            };
            this.$modalService.create("codeEntry/generic", modal).setReturnElement(this.returnFocusEl).on('hidden',()=>{
                $('.btn-submit-code').remoteAttr('disabled');
            })
        }
    }
};
</script>
