import merge from 'lodash/merge';	// Using lodash for ONE thing :(

const helpers = {
	allowedRouteProperties: ['name', 'path', 'component', 'redirect', 'alias', 'beforeEnter', 'meta', 'caseSensitive', 'pathToRegexOptions'],
	allowedGroupProperties: ['prefix', 'alias', 'beforeEnter', 'meta', 'caseSensitive', 'pathToRegexOptions'],

	routes: [],
	groupOptions: [],
	parents: [],
	viewResolver: c => c,
	mergeOptions: function () {
		let result = {};
		
		for (let i = 0; i < arguments.length; i++) {
			let backup = Object.assign({}, result),
				toAssign = arguments[i];

			Object.assign(result, toAssign);

			// Handle prefix
			if (toAssign.prefix) {
				if (backup.prefix) {
					result.prefix = backup.prefix + '/' + toAssign.prefix.replace(/^\//, '');
				}
				result.prefix = '/' + result.prefix.replace(/^\/|\/$/g, '');
			}

			// handle beforeEnter
			if (toAssign.beforeEnter) {
				result.beforeEnter = helpers.applyGuards(toAssign.beforeEnter, backup.beforeEnter);
			}

			// handle meta
			if (toAssign.meta && backup.meta) {
				result.meta = merge({}, backup.meta, toAssign.meta);
			}
		}

		return result;
	},
	applyGuards: function(guards, currentGuards) {
		if (!Array.isArray(guards)) {
			guards = [guards];
		}

		if (currentGuards) {
			guards.unshift(currentGuards);
		}

		return function(to, from, next) {
			let promise = Promise.resolve();

			guards.forEach((guard) => {
				promise = promise.then(() => {
					return new Promise((resolve, reject) => {
						guard(to, from, (route) => {
							if (route === undefined) {
								resolve();
							}
							else {
								reject(route);
							}
						});
					});
				});
			});

			promise
				.catch(r => r)
				.then(r => next(r));
		};
	},
	filterOptions: function(obj, allowedProps) {
		let result = {};
		for (let i in obj) {
			if (allowedProps.includes(i)) {
				result[i] = obj[i];
			}
		}

		return result;
	}
};

function createRoute(data) {
	let currentOptions = helpers.groupOptions.length
		? helpers.groupOptions[helpers.groupOptions.length - 1]
		: {};
	
	data = helpers.mergeOptions(currentOptions, data);

	if (data.path == '' || data.path == '/') {
		let parent = helpers.parents.length
			? helpers.parents[helpers.parents.length - 1]
			: null;
		
		if (parent) {
			delete data.path;
			return parent.options(data);
		}
	}

	if (data.prefix) {
		data.path = data.prefix + '/' + data.path.replace(/^\//, '');
		delete data.prefix;
	}

	if (data.hasOwnProperty('component')) {
		if (data.component) {
			data.component = helpers.viewResolver(data.component);
		}
		else {
			delete data.component;
		}
	}

	if (helpers.parents.length) {
		let currentParent = helpers.parents[helpers.parents.length - 1];
		currentParent.data.children.push(data);
	}
	else {
		helpers.routes.push(data);
	}

	return new RouteInstance(data);
}

const Route = {
	all: function() {
		return helpers.routes;
	},
	setViewResolver: function(cb) {
		helpers.viewResolver = cb;
	},
	view: function(path, component) {
		return createRoute({ path, component });
	},
	redirect: function(path, redirect) {
		return createRoute({ path, redirect });
	},
	group: function(options, defineRoutes) {
		let currentOptions = helpers.groupOptions.length
			? helpers.groupOptions[helpers.groupOptions.length - 1]
			: {};
		
		let newOptions = helpers.mergeOptions(currentOptions, options);
		newOptions = helpers.filterOptions(newOptions, helpers.allowedGroupProperties);

		helpers.groupOptions.push(newOptions);

		defineRoutes();

		helpers.groupOptions.pop();
	}
};

function RouteInstance(data) {
	this.data = data;
}

Object.assign(RouteInstance.prototype, {
	name: function(name) {
		this.data.name = name;
		return this;
	},
	guard: function(guards) {
		this.data.beforeEnter = helpers.applyGuards(guards, this.data.beforeEnter);

		return this;
	},
	children: function(defineRoutes) {
		this.data.children = this.data.children || [];

		helpers.parents.push(this);
		defineRoutes();
		helpers.parents.pop();

		return this;
	},
	meta: function(obj) {
		if (!this.data.meta) {
			this.data.meta = {};
		}

		this.data.meta = merge({}, this.data.meta, obj);
		return this;
	},
	options: function(options) {
		let newOptions = helpers.mergeOptions(this.data, options);
		newOptions = helpers.filterOptions(newOptions, helpers.allowedRouteProperties);

		Object.assign(this.data, newOptions);

		return this;
	}
});

export default Route;