import Vue from 'vue'
import Vuex from 'vuex'
import pathify from 'vuex-pathify'

import global from './modules/global'
import auth from './modules/auth'
import router from './modules/router'
import json from './modules/json'
import recaptcha from './modules/recaptcha'
import ada from './modules/ada'

Vue.use(Vuex)

export default new Vuex.Store({
	plugins: [
		pathify.plugin
	],
	
	modules: {
		global,
		auth,
		router,
		json,
		recaptcha,
		ada
	}
});