<template>
	<modal classes="modal-stars rules-modal" size="lg">
		<h5 class="modal-title text-center" slot="header">{{ prize.name }}</h5>
		<div slot="body" class="description" v-html="prize.full_desc || prize.details"></div>
	</modal>
</template>

<script>
	export default {
		name: "rewardDetail",

		props: {
			modal: {
				type: Object,
				required: true
			},
		    prize: Object,
		},
	}
</script>

<style scoped>

</style>