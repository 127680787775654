<template>
	<div class="main main-prize-form">
		<iframe :src="frameUrl" frameborder="0"></iframe>
	</div>
</template>

<style scoped>
	iframe {
		width: 100%;
		height: 400px;
	}
</style>
<script>
    export default {
        name:     'prizeForm',
        data() {
            return {}
        },
        mounted() {
        },
        computed: {
            frameUrl() {
                return this.$config.baseURL + '/prize-form/?_=' + this.$route.params.id + '&frame';
            }
        }
    }
</script>
