<template>
	<div class="container-fluid">
		<h1 class="text-center py-5 mb-50">Winners</h1>


			<!-- Sample data. Max of 12 only. -->
			<div class="winners-grid mt-5">
				<div v-for="n in 12" :key="n" class="grid-item text-center">
					<div class="bg-darkblue h-100 p-5 p-lg-5">
						<img :src="require(`../assets/images/2021/money${n}.png`)" alt="" role="presentation" class="d-block mx-auto mb-4">
						<h2 class="h4">First L.</h2>
						<strong>$15,000</strong>
					</div>
				</div>
			</div>

			<div class="text-center font-size-h4" v-if="!showWinners">
<!--				Coming Soon!-->
			</div>


	</div>
</template>


<script>
	import Vue from "vue";
	import {get} from "vuex-pathify";
	import {format} from 'date-fns';

	export default {
		name: "winners",

		components: {
		},

		data() {
			return {
				slickOptions:         {
					autoplay:      true,
					autoplaySpeed: 1500,
					arrows:        false
				},
				showWinners:          this.$store.get('auth/sessionData').showWinners,
				showCustomHtmlBlock1: this.$store.get('auth/sessionData').showWinnersHtmlBlock1,
				showCustomHtmlBlock2: this.$store.get('auth/sessionData').showWinnersHtmlBlock2,
				lastYear:             format(new Date() - 1, 'yyyy'),
				thisYear:             format(new Date(), 'yyyy')
			};
		},
		asyncComputed: {
			/**
			 * Create the file winners.json inside these two directories:
			 * ./public/static/files/
			 * ./src/frontend/static/files/
			 *
			 */
			winners:                 {
				get() {
					if (!this.showWinners) {
						return null;
					}
					return new Promise((resolve, reject) => {
						this.$http
							.get(this.staticBaseUrl+"winners.json")
							.then(response => {
								resolve(response.data);
							})
							.catch(error => {
								this.showWinners = false
								reject(error.message);
							});
					});
				},
			},
			winners_photos_customer: {
				get() {
					if (!this.showWinners || this.userType !== 'customer') {
						return null;
					}
					return new Promise((resolve, reject) => {
						this.$http
							.get(this.staticBaseUrl+"winners_photos_customer.json")
							.then(response => {
								resolve(response.data);
							})
							.catch(error => {
								reject(error.message);
							});
					});
				},
			},
			winners_photos_employee: {
				get() {
					if (!this.showWinners || this.userType !== 'employee') {
						return null;
					}
					return new Promise((resolve, reject) => {
						this.$http
							.get(this.staticBaseUrl+"winners_photos_employee.json")
							.then(response => {
								resolve(response.data);
							})
							.catch(error => {
								reject(error.message);
							});
					});
				},
			},
			customHtmlBlock1:        {
				get() {
					if (!this.showCustomHtmlBlock1) {
						return null
					}

					return new Promise((resolve, reject) => {
						this.$http
							.get(this.staticBaseUrl + "winners_html_block_top.html")
							.then(response => {
								resolve(response.data);
							})
							.catch(error => {
								this.showCustomHtmlBlock1 = false
								reject(error.message);
							});
					});
				},
			},
			customHtmlBlock2:        {
				get() {
					if (!this.showCustomHtmlBlock2) {
						return null
					}
					return new Promise((resolve, reject) => {
						this.$http
							.get(this.staticBaseUrl + "winners_html_block_bottom.html")
							.then(response => {
								resolve(response.data);
							})
							.catch(error => {
								this.showCustomHtmlBlock2 = false
								reject(error.message);
							});
					});
				},
			},

		},
		computed:      {
            staticBaseUrl() {
                let origin = this.$config.serverUrl + '/../static/files/';
                if (this.$config.env === 'local') {
                    origin = location.origin + '/static/files/';
                }
                return origin;
            },
			user: get("auth/user"),

			userType() {
				let userType = this.$store.get('auth/currentUserType') || '';
				return userType.toLowerCase();
			},
			filteredChecks() {
				if (!this.winners) {
					return null;
				}
				return this.winners.filter(check => check.type === this.userType);
			}
		}
	};
</script>
