<template>
	<modal v-bind="modalOpts">
		<h5 class="modal-title text-center" slot="header" v-html="title"></h5>
		<div slot="body" v-html="message"></div>
	</modal>
</template>

<script>
    export default {
        name:     'generic',
        props:    {
			modal: {
				type: Object,
				required: true
			},
            title:   String,
            message: String,
            theme:   String,
            opts:    Object
        },
        computed: {
            modalOpts() {
                let defaults = {
                    classes: []
                };

                return $.extend(defaults, this.opts || {});
            }
        }
    }
</script>