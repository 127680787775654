<template>
	<div>
		<div class="checkbox-animation-container ">
			<input class="sr-only sr-only-checkbox" type="checkbox" :checked="value" :id="id" @change="input($event)" :aria-required="required" :required="required">
			<label :for="id" class="check-box-label-container d-flex">
				<span class="check-box" role="presentation"/>
				<span class="check-box-label ml-2"><slot></slot></span>
			</label>
		</div>
	</div>
</template>

<script>
	export default {
		name:    "checkbox-custom",
		props:   ['value', 'id', 'required'],
		data() {
			return {
				theValue: this.value
			}
		},
		methods: {
			input(e){
				this.$emit('input', e.target.checked)
			}
		},
	}
</script>

<style scoped lang="scss">

</style>
