<template>
	<div tabindex="-1" aria-hidden="true">
		<!-- DFP -->
		<!--		<img :src="urlDFP" width=1 height=1 border=0 alt="" role="presentation"/>-->

	</div>
</template>
<script>

	export default {
		name:  "trackerGeneral",
		props: {
			name:            String,
			urlDFP:          String,
			scriptMediaMath: String
		},
		mounted() {

		},

	}
</script>
