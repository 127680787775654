<template>
	<modal classes="rules-modal" size="lg">
		<h5 class="modal-title text-center" slot="header">Rules</h5>
		<div slot="body" class="description">
			{{rules}}
		</div>
	</modal>
</template>

<script>
	export default {
		name: "rewardRules",

		props: {
			modal: {
				type: Object,
				required: true
			},
			rules: {
				type: String,
				required: true
			}
		},
	}
</script>

<style scoped>

</style>