<template>
	<span tabindex="0" v-b-tooltip="tip" v-ada :title="tip"><fa-icon pack="solid" icon="faInfoCircle"></fa-icon></span>
</template>

<script>
	/**
	 * Simple tooltip button component.
	 */

	export default {
		name:  "btn-tooltip",
		props: ['tip'],
		data() {
			return {
				// tip: this.$slots.default[0].text
			}
		}
	}
</script>

<style scoped lang="scss">
	span:active, span:focus {
		outline: 2px solid $light;
	}

	button {
		color: white;
		background: none;
		border: none;
		cursor: help;
	}
</style>
