window.blockAdBlock = false;

// import 'bootstrap'
import 'bootstrap/js/src/collapse';
import 'bootstrap/js/src/dropdown';

import 'blockadblock'
import '../utils/postMessage'

if (process.env.NODE_ENV !== 'production' || process.appVersion) {
	window.$ = $;
}
