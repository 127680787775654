<template>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-sm-8">
                <div class="bg-gray text-dark d-flex flex-column mx-auto mb-100 mt-4 mb-5 rounded p-4 p-xl-5">
                    <div class="container">
                        <div class="row justify-content-center">
                            <div class="w-100">
                                <div class="font-weight-bold pb-3 font-size-large">Sign in</div>
                                <FormulateForm
                                    name="loginForm"
                                    class="mb-5"
                                    @submit="submit"
                                    :schema="loginForm"
                                    v-model="formData"
                                    :form-errors="formErrors"
                                >
                                    <recaptcha3 ref="captchaComponent" context="login" v-if="$config.useCaptcha" :siteKey="$config.recaptcha.siteKey"></recaptcha3>
                                    <submit-button class="btn btn-rounded btn-yellow color-light py-1 px-5 mt-4 d-inline-block font-size-large" ref="btnSubmit" :loading="busy">Sign In</submit-button>
                                    <div class="align-middle mt-4 float-md-right text-md-right">
                                        <div class="font-size-sm mb-2">Having Trouble?
                                            <router-link :to="{name:'forgot-password'}">Recover password</router-link>
                                        </div>
                                        <div class="font-size-sm">Don't have an account?
                                            <router-link :to="{name:'new-player'}">Sign up</router-link>
                                        </div>
                                    </div>
                                </FormulateForm>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row justify-content-center px-2 px-md-5 py-5 mt-5 mt-xl-0 mb-5">
            <div class="px-0 py-5 mx-3 green-gradient-vertical text-center rounded winner-box position-relative">
                <img :src="require(`../assets/images/vacation.png`)" alt="" class="img-fluid">
                <div class="font-size-h5 color-dark mt-4">AUSTIN R. OF HILLSORO, OR</div>
                <div class="font-size-h4 text-light">HAWAIIAN VACATION<br>GRAND PRIZE</div>
                <div class="font-size-h5 color-dark">2023 WINNER</div>
            </div>
            <div class="px-0 py-5 mx-3 orange-gradient-vertical text-center rounded winner-box position-relative">
                <img :src="require(`../assets/images/groceries.png`)" alt="" class="img-fluid">
                <div class="font-size-h5 color-dark mt-4">MANDI F. OF MADRAS, OR</div>
                <div class="font-size-h4 text-light">GROCERIES<br>FOR A YEAR!</div>
                <div class="font-size-h5 color-dark">2023 WINNER</div>
            </div>
            <div class="px-0 py-5 mx-3 blue-gradient-vertical text-center rounded winner-box position-relative">
                <img :src="require(`../assets/images/flyaway.png`)" alt="" class="img-fluid">
                <div class="font-size-h5 color-dark mt-4">LUCERO M. OF SAINT HELENS, OR</div>
                <div class="font-size-h4 text-light">A TIMBERS FLYAWAY<br>GAME TRIP!</div>
                <div class="font-size-h5 color-dark">2023 WINNER</div>
            </div>
        </div>
    </div>
</template>

<script>
import Recaptcha3 from '../components/Recaptcha3';
import config from '../startup/config'

export default {
    name:       "returning-player",
    components: {Recaptcha3},
    data() {
        return {
            busy: false,
            //https://vueformulate.com/guide/validation/#customize-validation-messages
            loginForm:  [
                {
                    name:       'email',
                    label:      'Email',
                    placeholder: 'Email',
                    type:       'text',
                    validation: 'email',
                    class:       'col-12 col-md-6 d-inline-block px-0 pr-md-3 align-top',
                    value:      ''
                },
                {
                    name:       'password',
                    label:      'Password',
                    placeholder: 'Password',
                    type:       'password',
                    validation: 'required',
                    class:       'col-12 col-md-6 d-inline-block px-0 pl-md-3 align-top',
                    value:      ''
                }
            ],
            formErrors: [],
            formData:   {}
        }
    },
    methods: {

        async submit(data) {
            this.busy = true;
            if (this.$refs.captchaComponent) {
                data['captcha'] = await this.$refs.captchaComponent.getToken();
            }
            let response = await this.$http.post('user/login', data).catch(({response}) => {
                this.busy = false;
                if (response.data.error) {
                    this.formErrors = [response.data.error.errorMessage];
                }
            });
            if (response) {
                this.$root.$children[0].loginUserAndEnterCodes(response.data);
            } else {
                if (this.$refs.captchaComponent) {
                    this.$refs.captchaComponent.reset();
                }
            }
        }
    }
};
</script>
<style lang="scss">
/* .winner-box { */
/*     max-height: 400px; */
/* } */
    .winner-box {
        width: 100%;
        margin-bottom: 5rem;
		@media(min-width: $sm) {
            width: auto;
            min-width: 65%;
        }
		@media(min-width: $lg) {
            display: flex;
            flex-direction: column;
            flex: 0.23 1 0;
            justify-content: center;
            width: auto;
            min-width: 0;
        }
		@media(min-width: $xl) {
            margin-bottom: 3rem;
        }
        img {
            position: absolute;
            top: -50px;
            left: 0;
            right: 0;
            margin: auto;
        }
        &:last-child {
            img {
                top: -30px;
            }
        }
    }
</style>
