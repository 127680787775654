<template>
	<modal classes="modal-red">
		<h5 slot="header" slot-scope="{id}" :id="id">Error</h5>
		<div slot="body" class="text-center">
			{{ message }}
		</div>
	</modal>
</template>

<script>
	export default {
		name: 'errorLoggingIn',

		props: {
			message: {
				type:    String,
				default: 'There was an error logging you in. Please try again.'
			},
			modal:   {
				type:     Object,
				required: true
			}
		}
	}
</script>
