// Light
import {faExclamationCircle, faChevronLeft, faChevronRight, faBarcode, faCog, faTimes} from '@fortawesome/pro-light-svg-icons';

// Solid
import {faWindowClose,faExclamationTriangle,faCheck,faPlusSquare,faSyncAlt,faInfoCircle} from '@fortawesome/pro-solid-svg-icons';
// Regular

// Brands


export default {
	light: {
		faExclamationCircle,
		faChevronLeft,
		faChevronRight,
		faBarcode,
        faCog,
		faTimes
	},
	solid: {
		faWindowClose,
		faCheck,
		faExclamationTriangle,
		faPlusSquare,
		faSyncAlt,
		faInfoCircle
	},
	regular: {

	},
	brands: {

	}
};
