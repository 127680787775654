<template>
    <main class="reset-password">
        <div class="container">
            <div class="row">
                <div class="col-md-6 offset-md-3">
                    <h1>Reset Password</h1>
                    <p>To change your password, enter a new password below:</p>
                </div>
            </div>
            <form method="post" action="" @submit.prevent="submit">
                <div class="row">
                    <div class="col-md-6 offset-md-3">
                        <div class="alert alert-danger" v-if="error.errorMessage" v-html="error.errorMessage"></div>
                        <b-form-group id="password" label="Password" label-for="password-input" aria-describedby="password-feedback">
                            <b-form-input v-model="form.password" id="password-input" type="password" ref="password" :state="isError(error.password)"
                                          autocomplete="new_password"></b-form-input>
                            <b-form-invalid-feedback id="password-feedback" v-html="error.password"></b-form-invalid-feedback>
                        </b-form-group>
                        <b-form-group id="confirm-password" label="Confirm Password" label-for="confirm-password-input"
                                      aria-describedby="confirmPassword-feedback">
                            <b-form-input v-model="form.confirmPassword" id="confirm-password-input" type="password" ref="confirm-password" :state="isError(error.confirmPassword)"
                                          autocomplete="new_confirm"></b-form-input>
                            <b-form-invalid-feedback id="confirmPassword-feedback" v-html="error.confirmPassword"></b-form-invalid-feedback>
                        </b-form-group>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6 offset-md-3">
                        <submit-button :loading="busy" type="submit" class="btn btn-gray">Reset Password</submit-button>
                    </div>
                </div>
            </form>
        </div>
    </main>
</template>

<script>
import {get} from 'lodash';
import config from '../startup/config'

export default {
    name:    "reset-password",
    data() {
        return {
            busy: false,
            form:  {
                token:           this.$route.params.token,
                password:        '',
                confirmPassword: '',
            },
            error: {
                password:        null,
                confirmPassword: null
            }
        }
    },
    methods: {
        isError(value) {
            if (typeof value === 'undefined' || value === null) {
                return null;
            }
            return !value;
        },
        submit() {
            this.busy=true;
            this.$http.post('/user/resetPassword', this.form)
                .then(this.resetPasswordSuccess, this.resetPasswordFailure)
        },
        resetPasswordSuccess(user) {
            if (process.appVersion){
                this.$store.set('auth/user', user.data);
                this.$router.push({name: 'enter-code'});
                return;
            }
            let redirect = [
                location.protocol,
                '//',
                config.loggedInDomain
            ];
            if (location.port) {
                redirect.push(`:${location.port}`)
            }
            redirect.push('/enter-code');
            redirect.push('?sessionid='+user.data.user.ephemeral_login_token)
            redirect = redirect.join('');
            location.href=redirect;
        },
        resetPasswordFailure(xhr) {
            this.busy=false;
            let error = get(xhr, 'response.data.error', null);
            if (error) {
                console.error(error)
                this.error = error;
                this.form.password='';
                this.form.confirmPassword = '';
            }
            this.$refs.password.focus();
        }
    }
};
</script>

<style lang="scss" scoped>
.alert{
    a{
        font-weight: bold;
        color: #491217;
    }
}
</style>
