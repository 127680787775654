<script>
	import Vue from 'vue'

	function looper(fn, result, jObj) {
		jObj.contents().each((i, el) => {
			if (el.nodeType === 3) {
				result.push(el.nodeValue);
			}
			else {
				let $el = $(el),
					attrs = {};

				for (let i = el.attributes.length - 1; i >= 0; i--) {
					attrs[el.attributes[i].name] = el.attributes[i].value;
				}

				// Special code for components - Only FaIcon will be wired up
				if (el.tagName === 'FA-ICON') {
					result.push(
						fn('fa-icon', {
							props: attrs
						})
					);
				}
				else {
					let children = [];
					looper(fn, children, $el);
					result.push(
						fn(
							el.tagName.toLowerCase(),
							{
								attrs: attrs
							},
							children
						)
					);
				}
			}
		});
	}

	export default {
		name: "lang",

		props: {
			id: {
				type: String,
				required: true
			},
			tag: {
				type: String,
				default: ''
			},
			inline: {
				type: [Boolean, String],
				default: false
			},
			classes: {
				type: String,
				default: ''
			}
		},

		render(createElement) {
            let node = this.tag || (this.inline ? 'span' : 'div'),
                resp = this.$store.getters.getLang(this.id),
                altLang = this.$store.get('auth/sessionData@lang');

            // prefer lang data from /user request when it's available
            if (altLang && altLang[this.id]) {
                resp = altLang[this.id];
            }

			if (typeof resp === 'boolean') {
				// use the slots
				return createElement(
					node,
					{
						'class': this.classes || undefined,
						attrs: {
							'lang-id': this.id
						}
					},
					this.$slots.default
				);
			}

			// We need to convert what we were given into VNodes?
			// TODO: This just seems so... terribly wasteful. We have the html, which we turn into DOM elements, then to VNodes, which are later turned back into DOM elements
			resp = `<${node}>${resp}</${node}>`;
			resp = $(resp);

			let children = [];
			looper(createElement, children, resp);

			return createElement(
				node,
				{
					'class': this.classes || undefined,
					attrs: {
						'lang-id': this.id
					}
				},
				children
			);

			/*let comp = Vue.compile(resp);

			// Proxy parent
			comp.computed = {};
			for (let i in this.$parent._props) {
				comp.computed[i] = () => {
					return this.$parent[i];
				};
			}
			for (let i in this.$parent._data) {
				comp.computed[i] = () => {
					return this.$parent[i];
				};
			}
			for (let i in this.$parent.$options.computed) {
				comp.computed[i] = () => {
					return this.$parent[i];
				};
			}
			comp.methods = {};
			for (let i in this.$parent) {
				if (i[0] !== '$' && i[0] !== '_' && typeof this.$parent[i] === 'function' && !comp.computed[i]) {
					comp.methods[i] = this.$parent[i];
				}
			}

			return createElement(comp);*/
		}
	}
</script>
