<template>
	<rules-picker :lang="rulesLang" :type="rulesType"/>
</template>

<script>
	import rulesPicker from "../components/rules-picker.vue";

	export default {
		name: "rules",
		components: {
			rulesPicker
		},

		data() {
			return {
				rulesType: undefined,
				rulesLang: undefined
			}
		},

		created() {
			this.rulesType = this.$route.params.type;
			this.rulesLang = this.$route.params.lang;
		},

		watch: {
			$route: function(to) {
				this.rulesType = to.params.type;
				this.rulesLang = to.params.lang;
			}
		}
	};
</script>


<style lang="scss">
	.rules {
		font-size: $size16;
		ol {
			&:first-child {
				padding-inline-start: 15px;
			}
		}
		ol, ul, p {
			margin-bottom: $size15;
		}
		a {
			color: $dark;
			text-decoration: underline;
			&:hover, &:active, &:focus {
				color: $darkblue;
			}
		}
		.table {
			width: auto;
			color: $dark;
			caption {
				caption-side: top;
			}
			thead {
				th {
					padding: 0.25rem;
                    color: $dark;
                    border-top: 1px solid $dark;
                    border-bottom: 1px solid $dark;
				}
			}
			tr {
                td {
                    border-top: 1px solid $dark;
                }
				td:first-child {
					min-width:150px;
				}
				td:not(:first-child) {
					white-space: nowrap;
				}
			}
			@media (max-width: $lg - 1) {
				td,
				th {
					/*padding: 0.25rem;*/
					font-size: $size12;
				}
			}
		}
	}
</style>
