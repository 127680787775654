<template>
	<div class="main main-winners">
		<div class="container container-wide">
			<div class="page-header ticket">
				<lang id="prizes-title">
					<h1 class="page-title text-center text-uppercase font-size-h3 py-5">Prizes / Offers</h1>
				</lang>
			</div>
			<div v-if="prizes" class="prizes-container mt-5">
				<div class="row">
					<div v-for="(prize, i) in prizes" :key="i" :class="`col-sm-6 col-md-4 col-xl-3 my-50 item-${i} d-flex align-items-stretch`">

						<div class="prize-container px-4 py-3 w-100">
							<div v-if="prize.slot" class="slot-images">
								<div class="row relative">
									<div v-for="(img, n) in prize.slot" :key="n" class="col-4 p-0">
										<div :class="`reel square square-reel reel-${classify(img)}`">
											<span class="sr-only">{{img.toLowerCase()}}</span>
										</div>
									</div>
								</div>
							</div>
							<h2 class="my-3">{{prize.name}}</h2>
							<p class="small mb-1"><strong class="badge badge-red mr-2">{{ prize.available }}</strong> available {{ prize.available==1?'prize':'prizes' }}</p>
						</div>

					</div>
				</div>
			</div>
		</div>
		<page-sub-section :cols="['rules', 'faq']"></page-sub-section>
	</div>
</template>

<script>
	export default {
		name: "prizes",
		data() {
			return {
				loadError: false,
				refreshPrizes: false,
				busy: true
			};
		},
		computed: {},
		asyncComputed: {
			prizes: {
				get() {
					return new Promise((resolve, reject) => {
						this.loadError = false;
						this.$http
							.get("slot-prize-data")
							.then(response => {
								this.watchConnectivity(true);
								this.busy = false;
								resolve(response.data);
							})
							.catch(error => {
								this.loadError = true;
								this.busy = false;
								reject(error.message);
							});
					});
				},
				watch() {
					return this.refreshPrizes;
				}
			}
		},
		methods: {
			classify(name) {
				return name.replace(/[^\w]/gi, "").toLowerCase();
			}
		}
	};
</script>

<style lang="scss" scoped>
	h2 {
		font-size: $size18;
		line-height: 1.3;
	}

	.badge-red {
		color: $light;
		background-color: $red;
		font-family:'Courier New', Courier, monospace;
		font-size: $size14;
		font-weight: 700;
	}

	.text-yellow {
		color: $yellow;
	}

	.prize-container {
		background-color: $blue;
		border: 10px solid $light;
	}

	.reel {
		background-color: white;
		background-size: 100% auto;
		background-position: 50% 0%;
		border: 2px solid $darkblue;
		&-shop {
			background-position-y: 5.5%;
		}
		&-play {
			background-position-y: 11%;
		}
		&-win {
			background-position-y: 16.5%;
		}
		&-car {
			background-position-y: 22.5%;
		}
		&-cash {
			background-position-y: 28%;
		}
		&-cruise {
			background-position-y: 33.4%;
		}
		&-7 {
			background-position-y: 38.8%;
		}
		&-diamond {
			background-position-y: 44.3%;
		}
		&-bar {
			background-position-y: 50%;
		}
		&-bell {
			background-position-y: 55.8%;
		}
		&-fandango {
			background-position-y: 61%;
		}
		&-mystery {
			background-position-y: 100%;
		}
		&-handwbox {
			background-position-y: 72.2%;
		}
		&-gasgroceryicon {
			background-position-y: 66.8%;
		}
		&-plum {
			background-position-y: 77.7%;
		}
		&-watermelon {
			background-position-y: 83.3%;
		}
		&-grapes {
			background-position-y: 88.9%;
		}
		&-cherry {
			background-position-y: 94.3%;
		}
	}
</style>
