<template>
	<router-link-bak ref="link" v-bind="$props"><slot></slot></router-link-bak>
</template>

<script>
	export default {
		name: 'router-link',
		
		props: {
			to: {
				type: [String, Object],
				required: true
			},
			tag: {
				type: String,
				default: 'a'
			},
			exact: Boolean,
			append: Boolean,
			replace: Boolean,
			activeClass: String,
			exactActiveClass: String,
			event: {
				type: [String, Array],
				default: 'click'
			}
		},

		updated() {
			let activeClass = this.activeClass || this.$router.options.linkActiveClass || 'router-link-active';
			if (this.exact) {
				activeClass = this.exactActiveClass || this.$router.options.linkExactActiveClass || 'router-link-exact-active';
			}

			let el = $(this.$refs.link.$el);

			if (el.hasClass(activeClass)) {
				el.attr('aria-current', 'page');
			}
			else {
				el.removeAttr('aria-current');
			}
		}
	}
</script>