<template>
	<font-awesome-icon v-bind="getBoundVars()"></font-awesome-icon>
</template>

<script>
	import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome';
	import Icons from '../assets/js/fontawesome/index';

	export default {
		name: 'faIcon',

		components: {FontAwesomeIcon},

		props: {
			icon: {
				type: String
			},

			pack: {
				type: String,
				default: 'light'
			},

			classes: {
				type: String,
				default: ''
			},

			title: {
				type: String,
				default: ''
			},

			border: {
				type: Boolean,
				default: false
			},

			fixedWidth: {
				type: Boolean,
				default: false
			},

			flip: {
				type: String,
				default: null,
				validator: function validator(value) {
					return ['horizontal', 'vertical', 'both'].indexOf(value) > -1;
				}
			},
			mask: {
				type: [Object, Array, String],
				default: null
			},

			pulse: {
				type: Boolean,
				default: false
			},

			rotation: {
				type: Number,
				default: null,
				validator: function validator(value) {
					return [90, 180, 270].indexOf(value) > -1;
				}
			},

			size: {
				type: String,
				default: null,
				validator: function validator(value) {
					return ['lg', 'xs', 'sm', '1x', '2x', '3x', '4x', '5x', '6x', '7x', '8x', '9x', '10x'].indexOf(value) > -1;
				}
			},

			spin: {
				type: Boolean,
				default: false
			},

			transform: {
				type: [String, Object],
				default: null
			},

			symbol: {
				type: [Boolean, String],
				default: false
			}
		},

		computed: {
			iconData() {
				let icon = this.icon;
				if (icon.indexOf('-') !== -1) {
					icon = icon.replace(/-(\w)+?/g, function(fullMatch, capture){
						return capture[0].toUpperCase() + capture.substr(1);
					});

					if (!icon.startsWith('fa')) {
						icon = 'fa' + icon[0].toUpperCase() + icon.substr(1);
					}
				}

				return (Icons[this.pack] && Icons[this.pack][icon]) || false;
			},

			classArr() {
				let classes = [];

				if (this.classes) {
					classes = classes.concat(this.classes.replace(/\s{2,}/g, ' ').split(' '));
				}

				return classes;
			},

			realTitle() {
				if (this.iconData === false) {
					return 'Unregistered icon ' + this.pack + '/' + this.icon;
				}

				return this.title;
			}
		},

		methods: {
			getBoundVars() {
				return {
					icon: this.iconData || this.getInvalidIcon(),
					class: this.classArr,
					title: this.realTitle,
					border: this.border,
					fixedWidth: this.fixedWidth,
					flip: this.flip,
					mask: this.mask,
					pulse: this.pulse,
					rotation: this.rotation,
					size: this.size,
					spin: this.spin,
					transform: this.transform,
					symbol: this.symbol,
				};
			},

			getInvalidIcon() {
				return Icons['light']['faExclamationCircle'];
			}
		}
	}
</script>