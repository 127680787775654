/*  AsyncComputed - By Daniel Flynn

	The hoops I jump through because Vue does not expose any handles to their dependency tracking system...
    I sincerly appologize for the illegibility of the following code.
	
	Usage: (in component)
	export default {
		{...}
		asyncComputed: {
			asyncComp1() {	// function
				return new Promise((resolve, reject) => {
					// if success
					resolve({...});
					// if failure
					reject({...});
				});
			},

			asyncComp2: {	// object
				lazy: true,	// optional - Computes only the first time it's used and when dependencies change
				get() {		// 'get' is required in object syntax
					return new Promise({...});
				},
				watch() {	// optional
					// Extra dependencies for the computation
					// Simply use the variables and they will be tracked
				},
				error(...) {// optional
					// Called with whatever was passed to the 'reject' function in 'get'
				}
			},

			asyncComp3() {
				return "Returning a promise is not required... but why are we here if you aren't going to use them?"
			}
		}
		{...}
	}
*/

const suffix     = '$as_comp',
	  suffixMeta = '$as_comp_meta',
	  suffixLazy = '$as_comp_lazy';

const AsyncComputed = {
	install (Vue, pluginOptions) {
		pluginOptions = pluginOptions || {};

		Vue.config
			.optionMergeStrategies
			.asyncComputed = Vue.config.optionMergeStrategies.computed;

		Vue.mixin({
			beforeCreate() {
				this.$options.computed = this.$options.computed || {};

				for (const key in this.$options.asyncComputed || {}) {
					this.$options.computed[suffix + key] = AsyncComputed.makeComputedFunction(key, this.$options.asyncComputed[key]);
				}

				let origData = this.$options.data;

				this.$options.data = function() {
					let data = (typeof origData === 'function') ? origData.call(this) : origData;
					data = data || {};

					for (const key in this.$options.asyncComputed || {}) {
						let item = this.$options.asyncComputed[key];

						data[key + suffixMeta] = {
							status: 'idle',
							error: false
						};

						if (item.hasOwnProperty('lazy')) {
							data[key + suffixMeta].active = false;
							data[key + suffixLazy] = null;

							this.$options.computed[key] = AsyncComputed.makeLazyComputed(key);
						}
						else {
							data[key] = null;
						}
					}

					return data;
				};
			},

			created() {
				for (const key in this.$options.asyncComputed || {}) {
					let promiseId = 0;

					this.$watch(suffix + key, promise => {
						const thisPromise = ++promiseId;

						if (!(promise instanceof Promise)) {
							promise = Promise.resolve(promise);
						}

						promise.then(value => {
							if (thisPromise !== promiseId)
								return;

							if (this[key + suffixMeta].active !== false) {
								this[key + suffixMeta].status = 'resolved';
							}

							this[key + suffixMeta].error = false;
							this[key] = value;
						}).catch(err => {
							if (thisPromise !== promiseId)
								return;

							this[key + suffixMeta].status = 'rejected';

							if (this.$options.asyncComputed[key].error) {
								this[key + suffixMeta].error = this.$options.asyncComputed[key].error.call(this, err) || err;
							}
							else {
								this[key + suffixMeta].error = err;
							}

							if (this.$options.asyncComputed[key].clearOnError) {
								this[key] = null;
							}
						});
					}, {
						immediate: true
					});
				}
			},

			methods: {
				computedStatus(key) {
					return this[key + suffixMeta].status;
				},

				computedError(key) {
					return this[key + suffixMeta].error;
				}
			}
		});
	},

	makeComputedFunction(key, opt) {
		if (typeof opt === 'function') {
			opt = {
				get: opt
			};
		}

		let getter = function() {
			this[key + suffixMeta].status = 'loading';
			return opt.get.call(this);
		};

		if (opt.hasOwnProperty('watch')) {
			getter = function() {
				this[key + suffixMeta].status = 'loading';
				opt.watch.call(this);
				return opt.get.call(this);
			};
		}

		if (opt.hasOwnProperty('lazy')) {
			let nonLazy = getter;

			getter = function() {
				if (this[key + suffixMeta].active) {
					return nonLazy.call(this);
				}
				else {
					return this[key + suffixLazy];
				}
			};
		}

		return getter;
	},

	makeLazyComputed(key) {
		return {
			get() {
				this[key + suffixMeta].active = true;
				return this[key + suffixLazy];
			},

			set(value) {
				this[key + suffixLazy] = value;
			}
		};
	}
};

export default AsyncComputed;