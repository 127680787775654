<template>
	<div class="modal-container">
		<div class="modal fade" :style="{'z-index': zIndex + 1}">
			<div class="modal-dialog modal-dialog-centered" :class="{ ['modal-' + size]: size }" :role="role" tabindex="-1" :aria-labelledby="ariaLabelData">
				<div class="modal-content">
					<button v-if="showCloseX" type="button" class="modal-close" aria-label="Close" @click="inst.trigger('close')">
						Close <span aria-hidden="true">X</span>
					</button>
					<slot name="content">
						<div v-if="showHeader" class="modal-header">
							<slot name="header" :id="ariaLabelData">
								<h2 class="modal-title" :id="ariaLabelData">{{title}}</h2>
							</slot>
							
						</div>
						<div class="modal-body px-0">
							<slot name="body">
								<p>Modal body text goes here.</p>
							</slot>
						</div>
						<div v-if="showFooter" class="modal-footer">
							<slot name="footer">
								<button type="button" class="btn btn-primary " @click="inst.trigger('close')">Close</button>
							</slot>
						</div>
					</slot>
				</div>
			</div>
		</div>
		<div class="modal-backdrop" :style="{'z-index': zIndex}"></div>
	</div>
</template>

<script>
	import { ModalInstance, ModalService } from "@/utils/ModalService";

	export default {
	  name: "Modal",

	  props: {
	    modal: {
	      type: ModalInstance
	    },
	    title: {
	      type: String,
	      default: "Default Modal Template"
	    },
	    showCloseX: {
	      type: Boolean,
	      default: true
	    },
	    showHeader: {
	      type: Boolean,
	      default: true
	    },
	    showFooter: {
	      type: Boolean,
	      default: true
	    },
	    size: {
	      type: String,
	      default: ""
	    },
	    role: {
	      type: String,
	      default: "dialog"
	    },
	    focussableSelector: {
	      type: String,
	      default:
	        'a[href], input:not([disabled]), select:not([disabled]), textarea:not([disabled]), button:not([disabled]), iframe, object, embed, [tabindex="0"], [contenteditable]'
		},
		ariaLabel: {
			type: String,
			default: ''
		}
	  },

	  data() {
	    let data = {
	      inst: this.modal
	    };

	    if (!this.modal) {
	      let ancestor = this;
	      while ((ancestor = ancestor.$parent)) {
	        if (ancestor.modal) {
	          data.inst = ancestor.modal;
	          break;
	        }
	      }
	      if (!data.inst) {
	        console.error(
	          "modal.vue: Unable to find modal instance in the view hierarchy"
	        );
	      }
		}

		data.ariaLabelData = this.ariaLabel || `modal-header-${data.inst.id}`;

	    return data;
	  },

	  computed: {
	    zIndex() {
	      return 1050 + this.inst.zIndex * 10;
	    }
	  },

	  mounted() {
	    let $el = $(this.$el),
	      attempts = [$el.find(".modal-body"), $el];

	    for (let i = 0; i < attempts.length; i++) {
	      let focussable = attempts[i].find(this.focussableSelector);
	      if (focussable.length) {
	        focussable.first().focus();
	        break;
	      }
		}

		$el.ADANavigation(this.focussableSelector);

	    setTimeout(() => {
	      this.inst.trigger("opened");
	    }, 300);
	  },

	  destroyed() {
	    this.inst.trigger("closed");
	  },

	  methods: {
	    nextElement(dir) {
	      let $active = $(document.activeElement),
	        type = $active.attr("type");
	      if (type !== "text" && type !== "button") {
	        return;
	      }

	      let focusable = $(this.$el).find(this.focussableSelector).filter(':visible'),
	        ndx = focusable.index(document.activeElement);
		  ndx = (ndx + dir + focusable.length) % focusable.length;

	      focusable[ndx].focus();
	    }
	  }
	};
</script>

<style lang="scss" scoped>
	// Force visibility
	.modal-backdrop {
	  opacity: 0.5;
	  transition: opacity 300ms;
	}
	::v-deep.modal {
	  display: block;
	  opacity: 1;

	  .modal-dialog {
	    transform: translate(0, 0);
	  }
	  .input-group-addon {
	    width: 160px;
	    text-align: right;
	    justify-content: flex-end;
	    font-size: 0.8125rem;
	    font-weight: 700;
	  }
	  .input-group {
	    margin-top: 10px;
	  }
	}
	// Works with the transition-group in ModalServiceComponent2
	// TODO verify the below is ready for mobile
	.v-enter,
	.v-leave-to {
	  .modal {
	    opacity: 0;

	    .modal-dialog {
	      transform: translate(0, -25%);
	    }
	  }

	  .modal-backdrop {
	    opacity: 0;
	  }
	}
	::v-deep.field-list {
	  .select2-container {
	    display: flex;
	    align-items: center;
	    flex: 1 1 auto;
	    margin-bottom: 0;
	    position: relative;
	    z-index: 2;
	    border-top-left-radius: 0;
	    border-bottom-left-radius: 0;
	    .selection {
	      display: block;
	      width: 100%;
	      height: 100%;
	      font-size: inherit;
	      > span {
	        display: block;
	        width: 100%;
	        height: 100%;
	        border: 1px solid #ced4da;
	        border-radius: 3px;
	        border-top-left-radius: 0;
	        border-bottom-left-radius: 0;
	        padding: 0.375rem 0.75rem;
	        &.select2-selection--multiple {
	          padding: 0.28rem 0.45rem;
	        }
	      }
	    }
	  }
	  .select2-container--default
	    .select2-selection--single
	    .select2-selection__arrow {
	    height: 100%;
	    width: 30px;
	    b {
	      border-width: 10px 6px 0 6px;
	      margin-top: calc(50% - 20px);
	    }
	  }
	}
</style>
