import adaDirective from '../../utils/ada-directive';
import store from '../../store';
import {ModalService} from '../../utils/ModalService';

class SlickSliderAda {
	constructor(el) {
		this.el = el;

		$(this.el).on('beforeChange', this.beforeChange);
	}

	destroy() {
		$(this.el).off('beforeChange', this.beforeChange);
	}

	beforeChange(e, slick, currentSlide, nextSlide) {
		store.set('ada/alert', `Slide ${nextSlide + 1} of ${slick.slideCount}`);
	}

	static isValid(el) {
		return typeof el.slick == 'object';
	}
}

class InputDisabledAda {
	constructor(el) {
		this.el = el;
		this.update();
	}

	update() {
		$(this.el).attr('aria-disabled', this.el.disabled ? 'true' : 'false');
	}

	static isValid(el) {
		return typeof el.disabled != 'undefined';
	}
}

class TextLabelAda {
	constructor(el) {
		this.$el = $(el);
		this.update();
	}

	componentUpdate() {
		this.update();
	}

	update() {
		let label = this.$el.clone().find('[role="presentation"]').remove().end().text().trim();

		if (label) {
			this.$el.attr('aria-label', label);
		}
		else {
			this.$el.removeAttr('aria-label');
		}
	}

	static isValid(el, binding) {
		return $(el).is('button:not([aria-label])') || binding.value == 'aria-label';
	}
}

/**
 * apply to container element the property v-ada="['modal-non-interactive']"  to direct
 * its elements to be disabled whenever a modal is displayed.
 */
class ModalNonInteractiveAda {
	constructor(el) {
		this.el = el;
		this.disabled = false;
	}

	update(binding) {
		let disable = false;

		if (binding.value.indexOf('modal-non-interactive') >= 0) {
			disable = !!ModalService.getVisibleModals().length || disable;
		}
		if (binding.value.indexOf('menu-non-interactive') >= 0) {
			disable = store.get('global/navExpanded') || disable;
		}

		if (disable != this.disabled) {
			if (disable) {
				this.disable();
			}
			else {
				this.enable();
			}

			this.disabled = disable;
		}
	}

	disable() {
		$(this.el)
			.find('a[href], input, select, textarea, button, iframe, object, embed, [contenteditable], [tabindex]:not([tabindex="-1"])')
			.add(this.el)
			.not('[data-ada-disabled]')
			.each(function() {
				let props = {};
				if (typeof this.disabled != 'undefined') {
					props.disabled = this.disabled;
					this.disabled = true;
				}

				if (this.getAttribute('tabindex') != null) {
					props.tabindex = this.getAttribute('tabindex');
				}
				this.setAttribute('tabindex', '-1');

				if (this.getAttribute('aria-hidden')) {
					props.ariaHidden = this.getAttribute('aria-hidden').toLowerCase() == 'true';
				}
				this.setAttribute('aria-hidden', 'true');

				this.setAttribute('data-ada-disabled', JSON.stringify(props));
			});
	}

	enable() {
		$(this.el)
			.find('[data-ada-disabled]')
			.add(this.el)
			.each(function() {
				let props = JSON.parse(this.getAttribute('data-ada-disabled'));
				this.removeAttribute('data-ada-disabled');

				if (typeof props.disabled != 'undefined') {
					this.disabled = props.disabled;
				}

				if (typeof props.tabindex != 'undefined') {
					this.setAttribute('tabindex', props.tabindex);
				}
				else {
					this.removeAttribute('tabindex');
				}

				if (!props.ariaHidden) {
					this.removeAttribute('aria-hidden');
				}
			});
	}

	destroy() {
		if (this.disabled) {
			this.enable();
		}
	}

	static isValid(el, binding) {
		if (Array.isArray(binding.value)) {
			return binding.value.indexOf('modal-non-interactive') >= 0 || binding.value.indexOf('menu-non-interactive') >= 0;
		}

		return false;
	}
}

export default adaDirective([
	SlickSliderAda,
	InputDisabledAda,
	TextLabelAda,
	ModalNonInteractiveAda
]);
