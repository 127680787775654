import Vue from 'vue';
export const EventBus = new Vue({
	data() {
		return {
			when: {}
		}
	},

	beforeCreate() {
		let that = this,
			oldEmit = this.$emit;
		this.$emit = function (evt) {
			Vue.set(that.when, evt, true);
			oldEmit.apply(that, arguments);
		};

		this.$when = (evt, cb) => {
			if (this.when[evt]) {
				cb();
			}
			else {
				this.$on(evt, cb);
			}
		};
	},

	created() {
		this.$on('deviceready', () => {
			this.deviceReady = true;
		});
	}
});