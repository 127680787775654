<template>
    <div class="formulate-input-element formulate-input-element--select select-store-group"
         data-type="select"
    >
        <!-- STORE CHOOSER -->
        <!-- 1. STATE -->
        <div class="form-group">
            <!--			<lang id="signup-form-where-get-game-ticket">-->
            <!--				<label for="store_state" class="control-label">Where did you get your game ticket?<span class="label-req">*</span></label>-->
            <!--			</lang>-->
            <label for="store_state" class="label-req">State:</label>
            <select v-model="store_state" class="form-control" id="store_state" aria-required="true">
                <option disabled value="">- No state selected -</option>
                <option v-for="option in storeStates" :key="option" v-bind:value="option">{{ option }}</option>
            </select>

            <div class="form-error" v-if="errors.store_state">You must choose a state</div>
        </div>

        <!-- 2. CITY -->
        <div class="form-group" v-if="store_state">
            <label for="store_city" class="label-req">City:</label>
            <select v-model="store_city" class="form-control" id="store_city" @input="errors.store_city=false" aria-required="true">
                <option disabled value="">- No city selected -</option>
                <option v-for="option in storeCities" :key="option" v-bind:value="option">{{ option }}</option>
            </select>
            <div class="form-error" v-if="errors.store_city">You must choose a city</div>
        </div>

        <!-- 3. STORE ADDRESS / STORE_ID -->
        <div class="form-group" v-if="store_state && store_city">
            <label for="store_id" class="label-req">Store:</label>
            <select v-model="store_id" id="store_id" aria-required="true"  >
                <option disabled value="">- No store selected -</option>
                <option v-for="option in nearbyStores" :key="option[0]" v-bind:value="option[0]">{{ option[3] }}</option>
            </select>
            <div class="form-error" v-if="errors.store_id">You must choose a store</div>
        </div>
    </div>
</template>

<script>
import uniq from 'lodash/uniq';
import map from 'lodash/map';
import toArray from 'lodash/toArray';
import filter from 'lodash/filter';

export default {
    name:  "FormInputChooseStore",
    props: {
        context: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            store_state: '',
            store_city:  '',
            store_id:    '',
            errors:      {}
        }
    },
    asyncComputed: {
        stores: {
            get() {
                return new Promise((resolve, reject) => {
                    this.$http
                        .get(this.$config.storeListUrl)
                        .then(response => {
                            this.watchConnectivity(true);
                            this.$emit('ready');
                            resolve(response.data);
                        })
                        .catch(error => {
                            reject(error.message);
                        });
                });
            },
        }
    },
    computed:      {
        storeStates() {
            if (!this.stores) {
                return [];
            }
            let states = map(this.stores, 1);
            states = uniq(toArray(states)).sort();
            if (states.length === 1) {
                this.store_state = states[0];
            }
            return states;
        },

        storeCities() {
            if (!this.stores) {
                return [];
            }
            let stateStores = filter(this.stores, (store) => {
                return store[1] === this.store_state;
            });
            let cities = map(stateStores, 2);
            cities = uniq(toArray(cities)).sort();
            if (cities.length === 1) {
                this.store_city = cities[0];
            }
            return cities;
        },
        nearbyStores() {
            if (!this.stores) {
                return [];
            }
            let filteredStores = filter(this.stores, (store) => {
                return store[2] === this.store_city && store[1] === this.store_state;
            });
            if (filteredStores.length === 1) {
                this.store_id = filteredStores[0][0]
            }
            return filteredStores;
        },
    },
    methods:       {},
    watch:         {
        store_state: function (state) {
            this.store_city = '';
            this.store_id = '';
        },
        store_city:  function (city) {
            this.store_id = '';
        },
        store_id:    function (storeId) {
            this.context.model = storeId
        }
    }
}
</script>

<style scoped lang="scss">
.label-req:after {
    display: inline-block;
    content: '*';
    margin-left: 2px;

}
</style>
