(function() {
	window.Messages = {
		ListenMessage: function(cb, origin) {
			origin = origin || '';

			function messageHandler(e) {
			    var cont = false,
			    	checkFlag = true;
			    if (typeof origin == 'function') {
			    	cont = origin(e.origin);
			    }
			    else if (typeof origin == 'string') {
			    	if (origin === '') {
			    		cont = true;
			    	}
			    	else if (origin == '*') {
			    		cont = true;
			    		checkFlag = false;
			    	}
			    	else {
			    		cont = e.origin.toLowerCase() == origin.toLowerCase();
			    	}
			    }
			    else if (origin instanceof RegExp) {
			    	cont = e.origin.match(origin);
			    }

				if (typeof e.data !== 'object')
					return;

				if (checkFlag && !e.data.$__postMessage)
					return;

				if (cont) {
					if (checkFlag) {
						cb(e.data.payload, e);
					}
					else {
						cb(e.data, e);
					}
				}
			}

			window.addEventListener('message', messageHandler, false);
		},

		PostMessage: function(payload, target, origin) {
			target = target || window.parent;
			origin = origin || '*';

			target.postMessage({
				$__postMessage: true,
				payload: payload
			}, origin);
		}
	};
})();