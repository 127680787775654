import cookie from 'js-cookie';
import config from '../startup/config';
import {EventBus} from './EventBus'

const myCookie = {
	get(name) {
		return new Promise((resolve) => {
			if (process.appVersion) {
				EventBus.$when('deviceready', () => {
					window.cookieEmperor.getCookie(config.serverUrl, name, (data) => {
						resolve(data.cookieValue);
					}, (err) => {
						// error: cannot get cookie named 'name'
						resolve();
					});
				});
			}
			else {
				resolve(cookie.get(name));
			}
		});
	},

	set(name, value, opts) {
		return new Promise((resolve, reject) => {
			if (process.appVersion) {
				EventBus.$when('deviceready', () => {
					window.cookieEmperor.setCookie(config.serverUrl, name, value, () => {
						resolve(data.cookieValue);
					}, (err) => {
						// error: cannot set the cookie
						reject();
					});
				});
			}
			else {
				cookie.set(name, value, opts || {});
				resolve();
			}
		});
	}
};

export default myCookie;
