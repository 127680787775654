<template>
	<iframe :src="testFrameUrl" frameborder="0" width="1" height="1" v-if="testing"></iframe>
</template>

<script>
	let baseUrl = 'https://www.myda.ws/testcookie.php';
	export default {
		name: 'thirdPartyCookies',

		props: {
			title: {
				type: String,
				default: 'Notice'
			}
		},
		data() {
			return {
				nonce: null,
				testFrameUrl: null,
				cookieChecks: 0,
				testing: false
			};
		},

		created() {
			this.$EventBus.$on('postMessage', this.messageHandler);
		},

		destroyed() {
			this.$EventBus.$off('postMessage', this.messageHandler);
		},

		methods: {
			check() {
				// begin cookie check test
				this.cookieChecks = 0;
				this.testing = true;
				this.nonce = $.now();
				this.testFrameUrl = baseUrl + '?q=' + this.nonce;
			},

			messageHandler(event) {
				if ((event.data&&event.data.type !== '3pc') || !this.testing) {
					return;
				}
				// cookie check #this.cookieChecks - received: event.data
				if (this.cookieChecks === 0) {
					this.testFrameUrl = baseUrl;
				} else {
					if (this.nonce == event.data.value) {
						// good
						// console.log('[cookie check] cookies are okay');
					} else {
						//bad
						this.$emit('error');
						this.$modalService
							.create('browserSettingsWarning', {
								title: this.title
							});
						console.error('[cookie check] 3rd party cookies not being set!');
						// console.log('nonce=', this.nonce);
						// console.log('received=', event.data.value);
					}
					// cookie check test complete
					this.testing = false;
				}
				this.cookieChecks++;
			}
		}
	}
</script>
