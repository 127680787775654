<template>
	<modal classes="  rules-modal" size="lg">
		<div class="modal-title text-center" slot="header"></div>
		<div slot="body">
			<rules-picker :type="type" />
		</div>
	</modal>
</template>

<script>
	import rulesPicker from '../rules-picker';

    export default {
        name:       'RulesModal',
        props: {
			modal: {
				type: Object,
				required: true
			},
			type: String
		},
        components: {
            rulesPicker
        }
    }
</script>