<template>
    <div v-if="state===0" class="text-center">
        <fa-icon icon="faCog" spin size="lg"></fa-icon>
    </div>
    <div v-else-if="state===1" v-html="html"></div>
    <h4 v-else class="text-center pt-5">Coming Soon</h4>

</template>

<script>
import store from 'store';

export default {
    name: "external-page",
    data() {
        return {
            state: 0,
            html:               null,
            cacheKey:           null,
            htmlCache:          null,
            contentUnavailable: false,
            url:                null
        }
    },
    created() {
    },
    computed: {

    },
    methods:  {
        loadPage()
        {
            this.$http.get(this.url).then(response => {
                let data = response.data;
                this.html = data;
                this.state=1;
            }, error => {
                this.state = 2;
            }).catch(error => {
                this.state = 2;
            }).finally(_ => {
                this.$emit('mounted');
            });
        }
    },
    watch:    {
        $route: {
            handler(to) {
                let path = to.path
                let pages = this.$store.get('auth/sessionData@pages');
                const home = () =>{
                    this.$router.replace({name: 'home'});
                }
                if (!pages) {
                    return home();
                }
                let currentPage = pages.find(page => page.abspath === path);
                if (!currentPage){
                    return home();
                }
                this.cacheKey = `page:${currentPage.name}`;
                this.url = currentPage.url;
                // load the content from the server
                this.loadPage();
            },
            immediate: true
        }
    }
}
</script>

<style scoped>

</style>
