import axios from 'axios';
import {store} from './storage'
import vuexStore from '../store'

import router from '../router'
import config from '../startup/config'
import {ModalService} from './ModalService';
import {EventBus} from "./EventBus";

// game expects form data and responds with json
let toFormData = function (data) {
	let fd = new FormData();
	for (let key in data) {
		if (data.hasOwnProperty(key)) {
			fd.append(key, data[key]);
		}
	}
	return fd;
};
let requests = {
	baseURL:          config.serverUrl + '/app',
	withCredentials:  true,
	transformRequest: [toFormData]
};

let rawApi = axios.create(requests);

rawApi.interceptors.response.use(function (resp) {
	return resp;
}, function (err) {
	if (err.response) {
		if (err.response.status === 401 || err.response.status === 403) {
			/* vuexStore.dispatch('auth/refreshUser').then((user) => {
				if (user == null) {		// Yep... we're logged out
					ModalService.create('noLongerAuthenticated');
				}
			}); */
		}
	}
	return Promise.reject(err);
});
rawApi.interceptors.request.use(function (axiosConfig) {
	let session_id = vuexStore.get('auth/session_id');
	if (session_id && session_id.length >= 6) {
		axiosConfig.headers['X-AUTH'] = session_id;
	}
	if (config.appVersion) {
		let appVersionValue = `${config.appVersion}`;
		if (typeof device !== 'undefined') {
			appVersionValue = appVersionValue + `-${device.platform}`;
		}

		axiosConfig.headers['X-Mobile-App'] = appVersionValue;
	}
	return axiosConfig;
});

export default rawApi;
