<template>
    <b-form-group label="Date of Birth">
        <b-form-select v-model="month" :options="months" @input="emitValue"></b-form-select>
        <b-form-select v-model="day" :options="days" @input="emitValue"></b-form-select>
        <b-form-select v-model="year" :options="years" @input="emitValue"></b-form-select>
    </b-form-group>
</template>

<script>
export default {
    name:     "FormInputDOB",
    props:{
        value:'String'
    },
    computed: {
        months() {
            return ["January", "February", "March", "April", "May", "June", "July",
                "August", "September", "October", "November", "December"];
        },
        days() {
            let d = [];
            for (let i = 1; i <= 31; i++)
                d.push(i)
            return d;
        },
        years() {
            let years = [];
            let today = new Date();
            let finalYear = today.getFullYear();
            for (let year = finalYear - 120; year <= finalYear; year++)
                years.push(year)
            return years;
        }
    },
    data() {
        return {
            month: null,
            day:   null,
            year:  null
        }
    },
    watch:{},
    methods: {
        emitValue() {
            let month = `0${this.month}`.substr(-2), day=`0${this.day}`.substr(-2);
            this.$emit('input', `${this.year}-${month}-${day}`);
        }
    }
}
</script>

<style scoped>

</style>
