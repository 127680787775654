<template>
	<div class="container-fluid rules">
		<div class="row page-title">
			<div class="col-12 d-flex align-items-center justify-content-center">
				<h1 class="text-center py-5 m-0" v-html="rulesTitle"></h1>
			</div>
		</div>
		
        <div class="container" :lang="rulesLang" style="overflow-x: hidden">
            <div class="bg-gray p-3 p-lg-5 mt-3 mb-5 mx-0 mx-lg-3 rounded text-dark">
				<div v-if="!rulesLoaded" class="text-center text-dark">
					<lang id="rules-picker-loading" class="pb-3">
						<h2 class="text-dark">Rules Loading</h2>
						<fa-icon icon="faCog" spin size="lg"></fa-icon>
					</lang>
				</div>
				<div v-show="rulesLoaded" class="p-2 p-lg-5 text-dark">
					<component :is="rulesComp" ref="rulesComp" @mounted="rulesCompMounted"></component>
				</div>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
ol,
ul{
	padding-left: 0;
	li + li{

	}
	ol,
	ul{
		margin-top: 20px;
		padding-left: 20px;
	}
}
</style>
<script>
	const customerRulesEn = () => import(/* webpackChunkName: "customer-rules" */ './customer-rules-en');
	const customerRulesEs = () => import(/* webpackChunkName: "customer-rules" */ './customer-rules-es');
	const employeeRulesEn = () => import(/* webpackChunkName: "employee-rules" */ './employee-rules-en');
	const employeeRulesEs = () => import(/* webpackChunkName: "employee-rules" */ './employee-rules-es');

	const ruleCompLoaders = {
		customer: {
			en: customerRulesEn,
			es: customerRulesEs
		},
		associate: {
			en: employeeRulesEn,
			es: employeeRulesEs
		},
        employee: {
            en: employeeRulesEn,
            es: employeeRulesEs
        }
	};

	export default {
		name: 'rules-picker',

		props: {
			lang: {
				type: String,
				default: 'en'
			},
			type: {
				type: String,
				default: function() {
					return (this.$store.get('auth/currentUserType') || 'Customer');
				}
			}
		},

		data() {
			return {
				rulesLoaded: false,
				rulesTitle: '',

				rulesLang: this.lang,

				announceChange: false
			}
		},

		computed: {
			rulesComp() {
				return ruleCompLoaders[this.type.toLowerCase()][this.rulesLang.toLowerCase()];
			}
		},

		methods: {
			rulesCompMounted() {
				this.rulesTitle = (this.$refs.rulesComp.getTitle ? this.$refs.rulesComp.getTitle() : '') || 'Rules';
				this.rulesLoaded = true;

				if (this.announceChange) {
					this.alertADA(`Rules language is now ${this.rulesLang == 'en' ? 'English' : 'Spanish'}`);
				}

				this.announceChange = true;
			},

			toggleLanguage() {
				this.rulesLoaded = false;
				this.rulesLang = this.rulesLang == 'en' ? 'es' : 'en';
			}
		},

		watch: {
			lang(to) {
				this.rulesLang = to;
			}
		}
	}
</script>
