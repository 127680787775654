import Vue from 'vue'
import Modal from '../components/Modal'
import FaIcon from '../components/fa-icon'
import lang from '../components/lang'
import offlineMessage from '../components/offlineMessage'
import pageSubSection from '../components/pageSubSection'
import slick from 'vue-slick'
import RouterLinkAda from '../components/router-link-ada'

Vue.component('Modal', Modal);
Vue.component('FaIcon', FaIcon);
Vue.component('Lang', lang);
Vue.component('Slick', slick);
Vue.component('offlineMessage', offlineMessage);
Vue.component('pageSubSection', pageSubSection);
Vue.component('RouterLinkBak', Vue.component('RouterLink'));
Vue.component('RouterLink', RouterLinkAda);
